import { EsopPlanDetailsModel } from "../pages/shareholders/EsopPlanDetailsAgTable";
import { RoundDetailModel } from "./GenericCaptableModel";
import { InvestorStatus } from "./GlobalInvestors";

export type ShareholderDataModel = {
  id: string;
  name: string;
  type: string;
  holdingPercentage: number;
  shares: number;
  fdbShares: number;
  equityShares: number;
  prefShares: number;
  debentures: number;
  pricePerShare: number;
  roundIdentifierDetails: RoundDetailModel[];
  hasToShowDistinctiveNumber: boolean;
  hasDematShares: boolean;
  currentPrice: number;
  gain: number;
  networth: number;
  netWorthPercentage: number;
  shareRanges: string[];
  securityTypes: string[];
  holdingPercent: number;
  netWorth: number;
  sharesPerSecurity: {
    security: string;
    shares: number;
  }[];
};

export type ShareholderTransactionModel = {
  id: string;
  type: string;
  amountInvestedInCurrency: number;
  currency: string;
  exchangeRate: number;
  roundCurrency: string;
  exchangeRateForRound: number;
  numberOfShares: number;
  price: number;
  date: string;
  shareRanges: string[];
  folioNumber: string;
  certificateId: string;
  preHoldingShares: {
    [key: string]: number;
  } | null;
  postHoldingShares: {
    [key: string]: number;
  } | null;
  securityClass: string;
  secondaryInvestorName: string;
  amount: number;
  isPartlyPaid: boolean;
  actualSharePrice: number;
};

export type BeneficiaryModel = {
  id: string;
  name: string;
  securityClass: string;
  securityType: string;
  numberOfShares: number;
  holdingPercentage: number;
  amountInvested: number;
  beneficialInterest: number;
  certificateId: string;
  shareRanges: string;
  certificateRanges: string;
};

export type ShareholderSecurityModel = {
  name: string;
  securityType: string;
  securityClass: string;
  round: string;
  roundDetails: RoundDetailModel;
  demat: boolean;
  numberOfShares: number;
  pricePerShare: number;
  amountInvested: number;
  currentValue: number;
  securityClassId: string;
  certificateId: string;
  certificateName: string;
  certificateUrl: string;
  roundSecurityClassIds?: string[];
  fdbShares: number;
  remarks: string;
  ranges: number[][];
};

interface InvestorGroupDetails {
  id?: string;
  name: string;
}

export interface CapitalDetails {
  pps: number;
  valuation: number;
  eventId?: string;
  issuanceType?: string | null;
  paymentType?: string | null;
  authorizedEquity?: number | null;
  authorizedPreference?: number | null;
}

export interface PpsAndValuation {
  companyId: string;
  capitalDetails: CapitalDetails[];
}

export interface DematBody {
  certificateId: string;
  demat: boolean;
}

export type ShareholderProfile = {
  id: string;
  name: string;
  address: string;
  residence: string;
  nationality: string;
  depositoryName: string;
  isPromoter: boolean;
  isInstitutional?: boolean;
  type: string;
  groupName: string;
  email: string;
  contactNumber: string;
  dpId: string;
  clientId: string;
  companyId?: string;
  uniqueId: string;
  groupDetails?: InvestorGroupDetails[];
  investmentVehicleType?: string;
  isRelativeOfDirector: boolean;
  globalInvestorId?: string;
  globalInvestorStatus?: InvestorStatus;
  globalAccountEmail?: string;
  globalAccountId?: string;
  isCredentialGenerated?: boolean;
};

export type IndividualShareholderModel = {
  shareholderDetails: ShareholderDataModel;
  transactions: ShareholderTransactionModel[];
  beneficiaries: BeneficiaryModel[];
  shareholderSecurities: ShareholderSecurityModel[];
  shareholderProfile: ShareholderProfile;
  esopPlanDetailsList?: EsopPlanDetailsModel[];
};

export type SecurityRedemptionUpdateModel = {
  companyId: string;
  securityClassId: string;
  shareholderId: string;
  redemptionDate: string;
  redeemedAmount: number;
};

export interface BeneficiaryUpdateModel {
  id?: string;
  ranges?: number[][] | null;
  percentage?: number;
  bInvestorId: string;
  name?: string;
  numberOfShares?: number;
  isDeleted?: boolean;
  methodType?: string;
  uniqueId?: string;
  inputRanges?: string;
}

export enum BeneficiaryMethodType {
  percentage = "percentage",
  ranges = "ranges",
  numberOfShares = "numberOfShares",
}

export interface BeneficiaryCertificatesInput {
  certificateId: string;
  methodType: string;
  beneficiaryCertificates: BeneficiaryUpdateModel[];
}

export interface BeneficialInvestor {
  id: string;
  name: string;
  uniqueId?: string;
  email?: string;
  globalInvestorId: string;
  companyInvestorId: string;
}

export type SecurityConversionModel = {
  securityClassId: string;
  shareholderId: string;
  originalConversionRatio: number;
  baseValuation: number;
  sharePrice: number;
  investmentAmount: number;
  numberOfShares: number;
  valuationCap: number;
  securityType: {
    securityType: string;
    securityList: {
      securityClassId: string;
      securityClassName: string;
    }[];
  }[];
};

export type SecurityConversionUpdateModel = {
  companyId: string;
  securityClassId: string;
  shareholderId: string;
  conversionDate: string;
  convertibleShares: number;
  useFdbAtConversion: boolean;
  fdbShares: number;
  newSharePrice: number;
  updateConversionRatio: boolean;
  conversionSecurityClass: string;
  convertibleSecurityClassId: string;
  securityType?: string;
};

export type SecurityRedemptionModel = {
  amountInvested: number;
  redeemedAmount: number;
  investmentAmount: number;
  amountLeftToRedeeem: number;
  interest: number;
  eventDate: string;
  interestTime: number;
  interestType: string;
  interestRate: number;
  interestPeriod: number;
};

export interface ShareholderRangeModel {
  instrumentClasses: ShareholderInstrumentClass[];
  cInvestorId: string;
  cInvestorName?: string;
}

interface ShareholderInstrumentClass {
  instrumentClassId: string;
  numberOfShares: number;
  shareRanges: number[][];
  instrumentClassName?: string;
}

export interface ShareholderGroup {
  id?: string;
  name: string;
}

export interface UpdateTransactionInput {
  investmentRecordId: string;
  currency: string;
  exchangeRate: number;
  amountInvestedInCurrency: number;
}

export interface PartlyPaidDetails {
  sharePrice: number;
  numberOfShares: number;
  totalValue: number;
  amountInvested: number;
  amountDue: number;
  currentlyPaidDetails: CurrentlyPaid[];
  schedulePayDetails: SchedulePay[];
}

export interface CurrentlyPaid {
  id?: string;
  paidUpSharePrice: number;
  parPaidPerShare: number;
  premiumPaidPerShare: number;
  scheduledDate: string;
  paidUpDate: string;
  totalPaid: number;
}

export interface SchedulePay {
  uuid?: string;
  id?: string;
  paidUpSharePrice: number;
  scheduledDate: string;
  paidUpDate: string;
  toBePaid: number;
  isPaid: boolean;
  isDeleted: boolean;
}
