import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Popover, PopoverButton, PopoverPanel, Transition } from "@headlessui/react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import _ from "lodash";

import { useAuthStore } from "../../store/useAuthStore";
import useIsMobile from "../../utils/detectDevice";
import { handleClickForTracking } from "../../amplitudeAnalytics";
import { FilterConditionModel } from "../../pages/dashboardPage/AgGridCache";
import {
  useDeleteTableState,
  useGetTableFilterState,
  usePostTableFilterState,
} from "../../pages/dashboardPage/AgGridCacheQuery";

interface CloudSettingProps {
  tableId: string;
  isLoading: boolean;
  filterCondition: FilterConditionModel;
  setFilterCondition: (func: any) => void;
  className?: string;
}

const CloudSetting: React.FC<CloudSettingProps> = ({
  tableId,
  isLoading,
  filterCondition,
  setFilterCondition,
  className,
}) => {
  const { isMobile } = useIsMobile();
  const contextRef = React.useRef<HTMLDivElement>(null);
  const [show, setShown] = useState<boolean>(false);
  const { mutate: deleteTableState } = useDeleteTableState();
  const { data: tableState } = useGetTableFilterState(tableId);
  const { mutate: postOnFilter } = usePostTableFilterState();

  const debouncedSearch = useCallback(
    _.debounce((isColumnSave: boolean, isFilterSave: boolean) => {
      postOnFilter({
        tableId,
        companyId: "",
        filterData: {
          isColumnSave,
          isFilterSave,
          filterState: tableState?.filterState,
          columnState: tableState?.columnState,
        },
      });
    }, 0),
    [filterCondition, tableState, postOnFilter]
  );

  const handleMouseEvents = (isShown: boolean) => () => setShown(isShown);

  const handleClearAllStates = (): void => {
    deleteTableState(tableId, {
      onSuccess: () => {
        window.location.reload();
      },
    });
  };

  return (
    <Popover className="relative">
      <PopoverButton
        onClick={() => {
          handleMouseEvents(!show);
        }}
        onMouseEnter={handleMouseEvents(true)}
        onMouseLeave={handleMouseEvents(false)}
        className={`-m-1.5 flex flex-nowrap align-bottom ${
          show ? "text-gray-700" : "text-gray-400 hover:text-gray-500"
        } ring-0 border-0 focus:ring-0 border-white`}
      >
        <Icon
          onClick={() => {
            handleClearAllStates();
          }}
          icon={"mdi:table-sync"}
          className={`text-gray-500 hover:text-primary w-6 h-6 flex flex-nowrap align-center ${className}`}
        />
      </PopoverButton>

      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          onMouseEnter={handleMouseEvents(true)}
          onMouseLeave={handleMouseEvents(false)}
          ref={contextRef}
          className="absolute z-20 mt-4 flex w-screen max-w-max -left-20 -top-24"
          style={{ zIndex: 9999 }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="w-80 from-blue-50 to-purple-50 rounded-lg shadow-lg overflow-hidden border border-blue-200"
          >
            <div className="p-2 px-3 bg-black bg-opacity-70 flex items-center space-x-2">
              <Icon
                icon="mdi:table-cog"
                className="text-white"
                width="24"
                height="24"
              />
              <div>
                <h4 className="text-white font-bold text-md">
                  Reset Table State
                </h4>
                <p className="text-white text-xs">
                  Click here to reset the table to the default view.
                </p>
              </div>
            </div>
          </motion.div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default CloudSetting;
