import * as Yup from "yup";

export interface BoardMeetingModel {
  id?: string;
  name: string;
  dateOfMeeting: string;
  noOfDirectorsAttended?: number;
  directorsAttended?: attendedDirectors[];
  tags?: string[];
  notes?: string;
}

export interface BoardCompositionModel {
  companyId?: string;
  id?: string;
  numberOfDirectors: number;
}

export type attendedDirectors = {
  name: string;
  id: string;
};

export type SelectOptionType = {
  label?: string;
  value?: string | number;
  disabled?: boolean;
};

export const boardMeetingSchema = Yup.object().shape({
  name: Yup.string().required().label("Meeting Name"),
  dateOfMeeting: Yup.string().required().label("Date of meeting"),
  noOfDirectorsAttended: Yup.number()
    .label("No. of directors attended"),
});

export const boardCompositionSchema = Yup.object().shape({
  numberOfDirectors: Yup.string().required().label("No. of Board members"),
});
