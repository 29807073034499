import { Icon } from "@iconify/react";
import _ from "lodash";
import { useMatch, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  ChartBarIcon,
  ChartPieIcon,
  CurrencyDollarIcon,
  FolderOpenIcon,
  HomeIcon,
  ShieldExclamationIcon,
  UserGroupIcon,
  Cog6ToothIcon,
  AdjustmentsHorizontalIcon,
  CubeIcon,
  DocumentCheckIcon,
} from "@heroicons/react/24/solid";
import { HStack, redirectToHissa } from "../components/utils";
import { useCanUserAccess } from "../store/useRoleGrantStore";
import useIsMobile from "../utils/detectDevice";
import { classNames } from "../utils/string";
import { useGetUserRoleGrants } from "../queries/auth";

interface SideNavItemType {
  name: string;
  permissions: Array<string>;
  icon: any;
  path: string;
  useCurrentPortal?: boolean;
  enabledForEsopOnlyCompany?: boolean;
  enabled?: boolean;
}

function MainSideNavbar({ setDialogOpen }: any) {
  const canAccessCaptable = useCanUserAccess("read", "captable");
  const canAccessTransaction = useCanUserAccess("read", "transaction");
  const canAccessShareholder = useCanUserAccess("read", "shareholder");
  const canAccessInstrumentClass = useCanUserAccess("read", "instrumentclass");
  const canAccessRoundModelling = useCanUserAccess("read", "roundModeling");
  const canAccessTermsheet = useCanUserAccess("read", "termsheet");
  const canAccessSiteSettings = useCanUserAccess("read", "siteSettings");
  const canAccessReports = useCanUserAccess("read", "reports");
  const canAccessInvestorRights = useCanUserAccess("read", "investorRights");
  const { data: userRoleData } = useGetUserRoleGrants();

  const sideNavItemsMain: Array<SideNavItemType> = [
    {
      name: "Dashboard",
      permissions: ["ROLE_ADMIN"],
      icon: HomeIcon,
      enabled: canAccessCaptable,
      path: `/landing/dashboard`,
      useCurrentPortal: true,
    },
    {
      name: "Transactions",
      permissions: ["ROLE_ADMIN"],
      icon: CurrencyDollarIcon,
      enabled: canAccessTransaction,
      path: `/transactions/allTransactions`,
      useCurrentPortal: true,
    },
    {
      name: "Shareholders",
      permissions: ["ROLE_ADMIN"],
      icon: UserGroupIcon,
      enabled: canAccessShareholder,
      path: `/shareholders/overview`,
      useCurrentPortal: true,
    },
    {
      name: "Securities",
      permissions: ["ROLE_ADMIN"],
      icon: ShieldExclamationIcon,
      enabled: canAccessInstrumentClass,
      path: "/security/overview",
      useCurrentPortal: true,
    },
    // Only show Modeling if the user's role is "Admin Viewer"
    ...(userRoleData?.[0]?.role !== "Admin Viewer"
      ? [
          {
            name: "Modeling",
            permissions: ["ROLE_ADMIN"],
            icon: AdjustmentsHorizontalIcon,
            path: "/modeling/overview",
            enabled: canAccessRoundModelling,
            useCurrentPortal: true,
          },
        ]
      : []),
    {
      name: "Term Sheet",
      permissions: ["ROLE_ADMIN"],
      icon: ChartPieIcon,
      path: "/termsheet/overview",
      enabled: canAccessTermsheet,
      useCurrentPortal: true,
    },
    {
      name: "Financials",
      permissions: ["ROLE_ADMIN"],
      icon: ChartBarIcon,
      enabled: canAccessSiteSettings,
      enabledForEsopOnlyCompany: true,
      path: "/financials/overview",
      useCurrentPortal: true,
    },
    {
      name: "Documents",
      permissions: ["ROLE_ADMIN"],
      icon: FolderOpenIcon,
      enabled: canAccessSiteSettings,
      enabledForEsopOnlyCompany: true,
      path: "/companyDocuments/overview",
      useCurrentPortal: true,
    },
    //Dirty Commit {
    //   name: "Settings",
    //   permissions: ["ROLE_ADMIN"],
    //   icon: Cog6ToothIcon,
    //   enabled: canAccessSiteSettings,
    //   enabledForEsopOnlyCompany: true,
    //   path: "/settings/companyProfile",
    //   useCurrentPortal: true,
    // },
    {
      name: "Rights",
      permissions: ["ROLE_ADMIN"],
      icon: CubeIcon,
      enabled: canAccessInvestorRights,
      path: `/rights/overview`,
      useCurrentPortal: true,
    },
    {
      name: "Reports",
      permissions: ["ROLE_ADMIN"],
      enabled: canAccessReports,
      icon: DocumentCheckIcon,
      path: "/reports/overview",
      useCurrentPortal: true,
    },
  ];

  const [open, setOpen] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
      className={`hidden ease-in duration-300 lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block ${
        open ? "lg:w-60" : "lg:w-20"
      } lg:overflow-y-auto lg:bg-secondary lg:pb-4 lg:p-5`}
    >
      <div className="flex items-center justify-start h-8 gap-2 shrink-0">
        <img
          className="flex w-auto h-10 p-1 px-2 text-sm font-semibold leading-6 bg-white rounded-md cursor-pointer group gap-x-3 whitespace-nowrap"
          src="https://i.postimg.cc/8Cv31svZ/Screenshot-2023-12-02-133325-removebg-preview.png"
          alt="hissa_logo"
        />
        {open && (
          <img
            className="w-auto h-8"
            src="https://equion-dev.s3-us-west-2.amazonaws.com/hissa-logo.png"
            alt="hissa_logo"
          />
        )}
      </div>
      <nav>
        <ul className="pt-6">
          {sideNavItemsMain
            .filter((menu) => menu.enabled)
            .map((item) => (
              <SideNavItem key={item.name} sideNavItem={item} open={open} />
            ))}
        </ul>
      </nav>
    </div>
  );
}

function SideNavItem({
  sideNavItem,
  open,
}: {
  sideNavItem: SideNavItemType;
  open: boolean;
}) {
  const match = useMatch(`/${sideNavItem.path.split("/")[1]}/*`);
  const navigate = useNavigate();

  return (
    <li
      onClick={() =>
        sideNavItem.useCurrentPortal
          ? navigate(sideNavItem.path)
          : redirectToHissa(sideNavItem.path)
      }
      navigation-name={sideNavItem.name}
      key={sideNavItem.name}
    >
      <a
        className={classNames(
          match
            ? "bg-primary text-white"
            : "text-gray-300 hover:text-white hover:bg-gray-600",
          "group flex gap-x-3 rounded-md p-2 mb-1 text-sm cursor-pointer whitespace-nowrap leading-6 font-semibold"
        )}
      >
        <sideNavItem.icon className="w-6 h-6 shrink-0" aria-hidden="true" />
        <span className={`${!open && "hidden"} ease-in duration-300 `}>
          {sideNavItem.name}
        </span>
      </a>
    </li>
  );
}

export default MainSideNavbar;
