import { useEffect } from "react";
import { useAuthStore } from "../../store/useAuthStore";

function HandleHissaRedirect() {
  const { setAuth } = useAuthStore();
  useEffect(() => {
    const path = window.location.pathname;
    const params = window.location.search;
    if (params) {
      const urlSearchParams = new URLSearchParams(params);
      const accesstoken = urlSearchParams.get("logintoken");
      const xUsrToken = urlSearchParams.get("x-usr-token");
      const companyId = urlSearchParams.get("companyId");
      const redirectUri = urlSearchParams.get("redirectURL");
      if (xUsrToken) {
        localStorage.setItem("currentUser", xUsrToken);
      }
      if (accesstoken) {
        localStorage.setItem("accesstoken", accesstoken);
      }
      if (redirectUri) {
        localStorage.setItem("hissaUrl", redirectUri);
      }
      if (companyId) {
        localStorage.setItem("companyId", companyId);
        localStorage.setItem("companyId1", companyId);
      }
      setAuth({ isAuthenticated: true });
      if (!path.includes("resetpassword")) window.location.replace(path);
    }
  }, []);
  return null;
}

export default HandleHissaRedirect;
