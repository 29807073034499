import { useState } from "react";
import { useFormikContext } from "formik";
import { Input, Label, Required } from "../../components/shared/InputField";
import {
  Box,
  BoxContainer,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import { CompanyCapitalDetails } from "../../types/HistoricDataModel";
import { Headings } from "../../components/Headings";
import { CompanyDetailsPostModel } from "../../types/OnBoardDataModel";
import { SwitchButton } from "../../components/shared/SwitchButton";

export default function CompanyShareCapital() {
  const formik2 = useFormikContext<CompanyDetailsPostModel>();
  const { setFieldValue } = formik2;
  const [isApplySplit, setIsApplySplit] = useState<boolean>(false);

  const formik = useFormikContext<CompanyCapitalDetails>();
  const { values, errors, touched, getFieldProps } = formik;
  const fieldTouched = touched;
  const err = errors;
  return (
    <BoxContainer>
      <Headings text={"Share Capital Setting"} />
      <VStack className="w-full gap-8">
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
          <div className="flex-1">
            <Label>
              Authorised Equity
              <Required />
            </Label>
            <Input
              type="number"
              placeholder="Eg: 984839"
              className="w-120"
              {...getFieldProps("equityAuthorizedShareCapital")}
            />
            {fieldTouched?.equityAuthorizedShareCapital &&
              err?.equityAuthorizedShareCapital && (
                <Error text={err?.equityAuthorizedShareCapital} />
              )}
          </div>
          <div className="flex-1">
            <Label>
              Authorised Preference
              <Required />
            </Label>
            <Input
              type="number"
              placeholder="Eg: 984839"
              className="w-120"
              {...getFieldProps("preferenceAuthorizedShareCapital")}
            />
            {fieldTouched?.preferenceAuthorizedShareCapital &&
              err?.preferenceAuthorizedShareCapital && (
                <Error text={err?.preferenceAuthorizedShareCapital} />
              )}
          </div>
        </HStack>
        {/* <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
          <div className="flex-1">
            <Label>
              Paid Up Equity
              <Required />
            </Label>
            <Input
              type="number"
              placeholder="Eg: 984839"
              className="w-120"
              {...getFieldProps("equityPaidUpShareCapital")}
            />
            {fieldTouched?.equityAuthorizedShareCapital &&
              err?.equityAuthorizedShareCapital && (
                <Error text={err?.equityAuthorizedShareCapital} />
              )}
          </div>
          <div className="flex-1">
            <Label>
              Paid Up Preference
              <Required />
            </Label>
            <Input
              type="number"
              placeholder="Eg: 984839"
              className="w-120"
              {...getFieldProps("preferencePaidUpShareCapital")}
            />
            {fieldTouched?.preferencePaidUpShareCapital &&
              err?.preferencePaidUpShareCapital && (
                <Error text={err?.preferencePaidUpShareCapital} />
              )}
          </div>
        </HStack> */}

        <HStack>
          <div className="pt-8">
            <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
              <div className="flex-1 gap-8">
                <Label>Apply Split Filter</Label>
                <SwitchButton
                  label=""
                  value={formik2.values.splitFilter}
                  onClick={(e) => {
                    setIsApplySplit(!isApplySplit);
                    setFieldValue("splitFilter", !isApplySplit);
                  }}
                />
              </div>
            </HStack>
          </div>
          <div></div>
        </HStack>
      </VStack>
    </BoxContainer>
  );
}
