import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import UploadFile from "./components/UploadFile";
import Barchart from "./components/FinanceBarChart";
import LineChartData from "./components/FinanceLineChartData";
import FinancialCards from "./components/FinancialCards";
import { useGetFinancialData } from "../../queries/finance";
import { HStack, VStack } from "../../components/utils";
import { NoFinancials } from "./components/NoFinancialData";
import { useAuthStore } from "../../store/useAuthStore";
import BCHeader from "../../shared/BCHeader";
import { useGetCompanyDetails } from "../../queries/termsheet";
import Loader from "../../shared/Loader";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import { downloadS3File } from "../../utils/DownloadFile";
import AGGridBalanceSheetTable from "./components/tables/BalanceSheetAGTable";
import AGGridProfitAndLossTable from "./components/tables/ProfitAndLossAGTable";
import RatiosAGTable from "./components/tables/RatiosAGTable";
import RelatedPartyTransactionAGTable from "./components/tables/RelatedPartyTransactionAGTable";
import GenericChartHeader from "../../shared/ChartHeader";
import AGGridMisTable from "./components/tables/MisAGTable";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { useGetInvFinancialData } from "../../queries/investorPortal";
import { SwitchToggleButton } from "../../components/shared/SwitchButton";
import { useGetUserRoleGrants } from "../../queries/auth";

export type FinancialDataModel = {
  cin: string;
  companyId: string;
  data: any;
  isPrivate: boolean;
  misData: any;
  name: string;
  publicCompanyname: string;
  s3Url: string;
  timeline: string;
  financialType: FinancialType;
  timelineType: string;
  updatedDate: string;
  year: string;
  id: string;
};

export enum FinancialType {
  StandAlone,
  Consolidated,
}

export type FinancialResponseModel = {
  data: FinancialDataModel[];
  showRPT: {
    Monthly: boolean;
    Quarterly: boolean;
    Year: boolean;
  };
};

function FinanceWrapper(props: any) {
  const { cId, token } = useParams();
  const { data: nodeCompanyDetail } = useGetCompanyDetails();
  const companyId = nodeCompanyDetail?.companyDetails?.companyId;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [financialData, setFinancialData] = useState<FinancialDataModel[]>([]);
  const [timelineType, setTimelineType] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const { data: userRoleData } = useGetUserRoleGrants();

  const navigate = useNavigate();

  const canUserCreateFinancial = useCanUserAccess("create", "siteSettings");
  const { isPlaceholderData, data: response } = cId
    ? useGetInvFinancialData(cId || "", token || "")
    : useGetFinancialData(companyId || "");
  const [data, setdata] = useState<FinancialDataModel[]>();
  const [showRPTData, setShowRPTData] = useState(false);

  useEffect(() => {
    if (response) {
      setdata(response.data);

      const anyTrue = Object.values(response?.showRPT || "").includes(true);

      setShowRPTData(anyTrue);
      if (response.data.length > 0)
        setFinancialToggle(response.data[0].financialType);
    }
  }, [response]);

  const [dataToggle, setDataToggle] = useState("companies");
  const [financialToggle, setFinancialToggle] = useState<FinancialType>(
    FinancialType.StandAlone
  );
  const [chartHeader, setChartHeader] = useState("Balance Sheet");
  const [quarterlyData, setQuarterlyData] = useState(false);
  const [yearData, setYearData] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [monthlyData, setMonthlyData] = useState(false);
  const [isConsolidatedPresent, setIsConsolidatedPresent] = useState(false);

  const toggle = [
    {
      name: "companies",
      displayName: "Balance Sheet",
      onClickFunction: companiesSelection,
    },
    {
      name: "time",
      displayName: "Profit & Loss Reports",
      onClickFunction: timeSelection,
    },
    {
      name: "ratio",
      displayName: "Ratios",
      onClickFunction: ratioSelection,
    },
    {
      name: "relatedPartyTransaction",
      displayName: "Related Party Transaction",
      onClickFunction: relatedPartySelection,
    },
    {
      name: "mis",
      displayName: "MIS",
      onClickFunction: misSelection,
    },
  ];
  const filteredMisData =
    data?.filter(
      (item: any) => item.misData && Object.keys(item.misData).length !== 0
    ) ?? [];

  useEffect(() => {
    if (data === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [data]);

  function companiesSelection() {
    setDataToggle("companies");
    setChartHeader("Balance Sheet");
  }
  function timeSelection() {
    setDataToggle("time");
    setChartHeader("Profit and Loss Statement");
  }
  function ratioSelection() {
    setDataToggle("ratio");
    setChartHeader("Financial ratios");
  }

  function relatedPartySelection() {
    setDataToggle("relatedPartyTransaction");
    setChartHeader("Related Party Transactions");
  }

  function misSelection() {
    setDataToggle("mis");
    setChartHeader("MIS");
  }

  function handleFileUploadClose() {
    setShowModal(false);
    setSelectedOption("");
  }

  useEffect(() => {
    data?.sort((a: any, b: any) => parseInt(b.year, 10) - parseInt(a.year, 10));
    if (timelineType === "Year") {
      data?.sort(
        (a: any, b: any) => parseInt(b.year, 10) - parseInt(a.year, 10)
      );
    } else if (timelineType === "Quarterly") {
      data?.sort((a: any, b: any) => {
        if (a.year === b.year) {
          const quartersOrder = ["Q1", "Q2", "Q3", "Q4"];
          return (
            quartersOrder.indexOf(b.timeline) -
            quartersOrder.indexOf(a.timeline)
          );
        } else {
          return parseInt(b.year, 10) - parseInt(a.year, 10);
        }
      });
    } else if (timelineType === "Monthly") {
      data?.sort((a: any, b: any) => {
        if (a.year === b.year) {
          const monthsOrder = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return (
            monthsOrder.indexOf(b.timeline) - monthsOrder.indexOf(a.timeline)
          );
        } else {
          return parseInt(b.year, 10) - parseInt(a.year, 10);
        }
      });
    }

    if (data) {
      const groupedData = data.reduce((acc: any, item: any) => {
        const { timelineType } = item;
        if (!acc[timelineType] && item.financialType === financialToggle) {
          acc[timelineType] = [];
        }
        if (item.financialType === financialToggle)
          acc[timelineType].push(item);
        return acc;
      }, {});

      const consolidatedData = data.reduce((acc: any, item: any) => {
        const { financialType } = item;
        if (!acc[financialType]) {
          acc[financialType] = [];
        }
        acc[financialType].push(item);
        return acc;
      }, {});
      setIsConsolidatedPresent(
        consolidatedData &&
          consolidatedData.Consolidated?.length > 0 &&
          consolidatedData &&
          consolidatedData.StandAlone?.length > 0
      );
      const availableTimelineTypes = Object.keys(groupedData);
      if (
        availableTimelineTypes.includes("Year") &&
        availableTimelineTypes.includes("Quarterly") &&
        availableTimelineTypes.includes("Monthly")
      ) {
        setYearData(true);
        setQuarterlyData(true);
        setMonthlyData(true);
        setTimelineType("Year");
        setFinancialData(
          groupedData.Year.filter(
            (item: any) => item.financialType === financialToggle
          )
        );
      } else if (
        availableTimelineTypes.includes("Year") &&
        availableTimelineTypes.includes("Quarterly")
      ) {
        setYearData(true);
        setQuarterlyData(true);
        setTimelineType("Year");
        setFinancialData(
          groupedData.Year.filter(
            (item: any) => item.financialType === financialToggle
          )
        );
      } else if (
        availableTimelineTypes.includes("Year") &&
        availableTimelineTypes.includes("Monthly")
      ) {
        setYearData(true);
        setMonthlyData(true);
        setTimelineType("Year");
        setFinancialData(
          groupedData.Year.filter(
            (item: any) => item.financialType === financialToggle
          )
        );
      } else if (
        availableTimelineTypes.includes("Monthly") &&
        availableTimelineTypes.includes("Quarterly")
      ) {
        setMonthlyData(true);
        setQuarterlyData(true);
        setTimelineType("Monthly");
        setFinancialData(
          groupedData.Monthly.filter(
            (item: any) => item.financialType === financialToggle
          )
        );
      } else if (availableTimelineTypes.includes("Year")) {
        setYearData(true);
        setTimelineType("Year");
        setFinancialData(
          groupedData.Year.filter(
            (item: any) => item.financialType === financialToggle
          )
        );
      } else if (availableTimelineTypes.includes("Quarterly")) {
        setQuarterlyData(true);
        setTimelineType("Quarterly");
        setFinancialData(
          groupedData.Quarterly.filter(
            (item: any) => item.financialType === financialToggle
          )
        );
      } else if (availableTimelineTypes.includes("Monthly")) {
        setMonthlyData(true);
        setTimelineType("Monthly");
        setFinancialData(
          groupedData.Monthly.filter(
            (item: any) => item.financialType === financialToggle
          )
        );
      }
    }
  }, [data]);
  // const financialTypeToggle = [
  //   {
  //     name: "StandAlone",
  //     displayName: "Stand Alone",
  //     onClickFunction: (() => {
  //       setFinancialToggle('StandAlone');
  //       if (data) {
  //         const groupedData = data.reduce(
  //           (acc: any, item: any) => {
  //             const { financialType } = item;
  //             if (!acc[financialType]) {
  //               acc[financialType] = [];
  //             }
  //             acc[financialType].push(item);
  //             return acc;
  //           },

  //           {}
  //         );
  //         setFinancialData(groupedData.StandAlone);
  //       }
  //     })
  //   },
  //   {
  //     name: "Consolidated",
  //     displayName: "Consolidated",
  //     onClickFunction: (() => {
  //       setFinancialToggle('Consolidated');
  //       if (data) {
  //         const groupedData = data.reduce(
  //           (acc: any, item: any) => {
  //             const { financialType } = item;
  //             if (!acc[financialType]) {
  //               acc[financialType] = [];
  //             }
  //             acc[financialType].push(item);
  //             return acc;
  //           },

  //           {}
  //         );
  //         setFinancialData(groupedData.Consolidated);
  //       }
  //     })
  //   },
  // ];

  function filterByTimelineType(data: any[], timelineType: string): any[] {
    return data.filter((item: any) => item.timelineType === timelineType);
  }

  function handleFinancialTypeClick(type: string) {
    if (data) {
      const groupedData = data.reduce(
        (acc: any, item: any) => {
          const { financialType } = item;
          if (!acc[financialType]) {
            acc[financialType] = [];
          }
          acc[financialType].push(item);
          return acc;
        },

        {}
      );
      const timeLineData =
        type === "StandAlone"
          ? groupedData.StandAlone
          : groupedData.Consolidated;
      const timeLine: string[] = [];
      timeLineData.forEach((obj: any) => {
        if (!timeLine.includes(obj.timelineType))
          timeLine.push(obj.timelineType);
      });
      const primaryTimelineType = timeLine.includes("Year")
        ? "Year"
        : timeLine.includes("Quarterly")
        ? "Quarterly"
        : "Monthly";

      const standAloneData = filterByTimelineType(
        groupedData.StandAlone,
        primaryTimelineType
      );
      const consolidatedData = filterByTimelineType(
        groupedData.Consolidated,
        primaryTimelineType
      );

      setYearData(timeLine.includes("Year"));
      setQuarterlyData(timeLine.includes("Quarterly"));
      setMonthlyData(timeLine.includes("Monthly"));
      setTimelineType(primaryTimelineType);
      setFinancialData(
        type === "StandAlone" ? standAloneData : consolidatedData
      );
    }
  }

  return (
    <div className={isPlaceholderData ? "loading min-h-full" : "min-h-full"}>
      {!cId && canUserCreateFinancial && (
        <HStack className="items-center justify-between">
          <VStack>
            <BCHeader className="items-baseline" bcTitle="Financial Overview" />
            <p className="ml-3 -mt-4 font-medium sm:mt-0 sm:text-xs1 text-xxs text-descriptionColor whitespace-nowrap md:mr-12">
              Centralized financial dashboard. Visualize your company&apos;s key
              metrics.
            </p>
          </VStack>
          {data === undefined || financialData.length === 0 ? (
            <div></div>
          ) : (
            <HStack className="items-center gap-8">
              {isConsolidatedPresent && (
                <HStack>
                  <SwitchToggleButton
                    className="ml-4 text-sm font-medium"
                    label=""
                    value={isChecked}
                    onClick={() => {
                      setIsChecked(!isChecked);
                      setFinancialToggle(
                        isChecked
                          ? FinancialType.StandAlone
                          : FinancialType.Consolidated
                      );
                      handleFinancialTypeClick(
                        isChecked ? "StandAlone" : "Consolidated"
                      );
                    }}
                  />
                  <span className="inline-block w-24 pt-2 text-sm font-bold align-text-bottom">
                    {isChecked ? "Consolidated" : "Standalone"}
                  </span>
                </HStack>
              )}
              <PrimaryCTAButton
                event-name="Upload Reports Button"
                className="h-8 text-xs2 whitespace-nowrap"
                onClick={() => navigate("/financials/settings")}
                disabled={
                  userRoleData && userRoleData[0].role === "Admin Viewer"
                }
              >
                Edit Reports
              </PrimaryCTAButton>
            </HStack>
          )}
        </HStack>
      )}

      {data === undefined || response?.data?.length === 0 ? (
        <div className={`${loading === true ? "loading" : ""}`}>
          <NoFinancials
            cId={cId}
            companyId={cId ? cId || "" : companyId || ""}
            show={showModal}
            handleClose={handleFileUploadClose}
          />
        </div>
      ) : (
        <main className="">
          <div className="pb-8">
            <div className="overflow-x-auto">
              {data && financialData.length > 0 && (
                <FinancialCards financialReportDetails={financialData} />
              )}
            </div>
          </div>

          <Dialog open={showModal} maxWidth="md">
            <UploadFile
              companyId={cId ? cId || "" : companyId || ""}
              show={showModal}
              handleClose={handleFileUploadClose}
            />
          </Dialog>
          {!isPlaceholderData ? (
            <HStack className="gap-8">
              <div className="flex-1 pt-4 bg-white border rounded-md border-borderColor shadow-box">
                <GenericChartHeader heading={"Revenue vs Expenses"} />

                <HStack className="justify-between overflow-auto">
                  <div className="w-full m-6 ">
                    {data && (
                      <Barchart
                        data={financialData?.length > 0 ? financialData : []}
                      />
                    )}
                  </div>
                </HStack>
              </div>
              <div className="flex-1 pt-4 bg-white border rounded-md border-borderColor shadow-box">
                <GenericChartHeader heading={"Revenue vs Profits"} />
                <HStack className="justify-between overflow-auto">
                  <div className="w-full m-6 ">
                    {data && (
                      <LineChartData
                        data={financialData?.length > 0 ? financialData : []}
                      />
                    )}
                  </div>
                </HStack>
              </div>
            </HStack>
          ) : (
            <Loader />
          )}

          <div className="w-full h-auto pt-4 pb-8 mt-8 mb-8 bg-white border rounded-md align-center border-borderColor shadow-box">
            <HStack className="justify-between px-1">
              <HStack className="h-10 px-1 mt-4">
                {toggle
                  .filter(
                    (element) =>
                      !(
                        (element.displayName === "MIS" &&
                          filteredMisData.length === 0) ||
                        (element.displayName === "Related Party Transaction" &&
                          !showRPTData)
                      )
                  )
                  .map((element) => (
                    <p
                      key={element.name}
                      className={`font-medium px-4 ${
                        dataToggle === element.name
                          ? "text-red-500 border-b border-red-500 font-semibold"
                          : "text-gray-600"
                      } text-sm cursor-pointer`}
                      onClick={element.onClickFunction}
                    >
                      {element.displayName}
                    </p>
                  ))}
              </HStack>

              <div
                className="inline-flex m-4 bg-gray-100 rounded-md shadow-sm"
                role="group"
              >
                {yearData && (
                  <button
                    type="button"
                    className={`px-4 py-2 text-sm font-normal ${
                      timelineType === "Year"
                        ? " bg-orange-600  text-white rounded-md"
                        : "bg-gray-100"
                    }`}
                    onClick={() => {
                      const groupedData = data.reduce(
                        (acc: any, item: any) => {
                          const { timelineType } = item;
                          if (!acc[timelineType]) {
                            acc[timelineType] = [];
                          }
                          acc[timelineType].push(item);
                          return acc;
                        },

                        {}
                      );
                      setFinancialData(
                        groupedData.Year.filter(
                          (item: any) => item.financialType === financialToggle
                        )
                      );
                      setTimelineType("Year");
                      setSelectedOption("Year");
                    }}
                  >
                    Year
                  </button>
                )}
                {quarterlyData && (
                  <button
                    type="button"
                    className={`px-4 py-2 text-sm font-normal ${
                      timelineType === "Quarterly"
                        ? " bg-orange-600  text-white rounded-md"
                        : "bg-gray-100"
                    }`}
                    onClick={() => {
                      const groupedData = data.reduce(
                        (acc: any, item: any) => {
                          const { timelineType } = item;
                          if (!acc[timelineType]) {
                            acc[timelineType] = [];
                          }
                          acc[timelineType].push(item);
                          return acc;
                        },

                        {}
                      );
                      setFinancialData(
                        groupedData.Quarterly.filter(
                          (item: any) => item.financialType === financialToggle
                        )
                      );
                      setTimelineType("Quarterly");
                      setSelectedOption("Quarterly");
                    }}
                  >
                    Quarter
                  </button>
                )}
                {monthlyData && (
                  <button
                    type="button"
                    className={`px-4 py-2 text-sm font-normal ${
                      timelineType === "Monthly"
                        ? " bg-orange-600  text-white rounded-md"
                        : "bg-gray-100"
                    }`}
                    onClick={() => {
                      const groupedData = data.reduce(
                        (acc: any, item: any) => {
                          const { timelineType } = item;
                          if (!acc[timelineType]) {
                            acc[timelineType] = [];
                          }
                          acc[timelineType].push(item);
                          return acc;
                        },

                        {}
                      );
                      setFinancialData(
                        groupedData.Monthly.filter(
                          (item: any) => item.financialType === financialToggle
                        )
                      );
                      setTimelineType("Monthly");
                      setSelectedOption("Monthly");
                    }}
                  >
                    Month
                  </button>
                )}
              </div>
            </HStack>

              {data && dataToggle === "time" && (
                <AGGridProfitAndLossTable
                  data={financialData}
                  timelineType={timelineType}
                />
              )}

              {data && !isPlaceholderData && dataToggle === "companies" && (
                <AGGridBalanceSheetTable
                  data={financialData}
                  timelineType={timelineType}
                />
              )}
              {data && dataToggle === "relatedPartyTransaction" && (
                <RelatedPartyTransactionAGTable
                  data={financialData}
                  timelineType={timelineType}
                />
              )}

              {data && dataToggle === "ratio" && (
                <RatiosAGTable
                  data={financialData}
                  timelineType={timelineType}
                />
              )}

              {filteredMisData.length > 0 && dataToggle === "mis" && (
                <AGGridMisTable
                  data={financialData}
                  timelineType={timelineType}
                />
              )}
          </div>
        </main>
      )}
    </div>
  );
}

export default FinanceWrapper;
