import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetFinancialData } from "../../../queries/finance";
import FileUpload from "./UploadFile";
import { PropsData } from "../../../types/finance/Finance";
import { useAuthStore } from "../../../store/useAuthStore";
import EmptyPageIllustration from "../../../shared/EmptyPageIllustration";
import EmptyTableIllustration from "../../../shared/EmptyTableIllustration";
import {
  ButtonSize,
  PrimaryCTAButton,
} from "../../quickRound/CTAButtonComponents";
import { useGetUserRoleGrants } from "../../../queries/auth";

export const NoFinancials = (props: PropsData) => {
  const [showModal, setShowModal] = useState(false);
  const { data: _data } = useGetFinancialData(props.companyId || "");
  const { data: userRoleData } = useGetUserRoleGrants();

  function handleFileUploadClose() {
    setShowModal(false);
  }
  function handleFileUpload() {
    setShowModal(true);
  }
  return (
    <div>
      <div className="text-center">
        <EmptyTableIllustration
          text={"No reports uploaded yet. Add one to see your financial data"}
        />
        {!props.cId && (
          <PrimaryCTAButton
            event-name="Upload Reports Button"
            className="mt-4"
            buttonSize={ButtonSize.SMALL}
            onClick={() => handleFileUpload()}
            disabled={
              userRoleData && userRoleData[0].role === "Admin Viewer"
            }
          >
            Upload Reports
          </PrimaryCTAButton>
        )}

        <Dialog open={showModal} maxWidth="md">
          <FileUpload
            companyId={props.companyId}
            show={showModal}
            handleClose={handleFileUploadClose}
          />
        </Dialog>
      </div>
    </div>
  );
};
