import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { useNavigate, useParams } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";

import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";

import { useAgGridTableState } from "../../store/agGridTableStore";

import { EmptyTable } from "../../components/shared/EmptyTable";
import {
  DateRender,
  DocIconRender,
  DocumentNameRender,
  DownloadIconComponent,
  DownloadRender,
  SizeRender,
  TagsRender,
} from "./TransactionDocumentAgComponent";
import SearchInput from "../../components/shared/SearchInput";
import {
  ButtonSize,
  IconCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import AddShareholderProfile from "../../modals/AddShareholderProfile";
import { DocumentType } from "../../types/AllTransactionModel";
import AddTransactionDocument from "../../modals/AddTransactionDocument";
import { useGetUserRoleGrants } from "../../queries/auth";

export type TransactionDocTypes = {
  id: string;
  companyId: string;
  documentType: DocumentType;
  cin: string;
  name: string;
  key: string;
  size: string;
  tags: string[];
  updatedAt: string;
  eventId: string;
};

function TransactionDocumentsAgGridTable({
  transactionDocuments,
  eventId,
}: {
  transactionDocuments: TransactionDocTypes[] | [];
  eventId: string;
}) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const { data: userRoleData } = useGetUserRoleGrants();
  const { cId, token } = useParams() as {
    cId: string;
    token: string;
  };

  const [displayedRowCount, setDisplayedRowCount] = useState<number>(
    transactionDocuments?.length || 0
  );
  const navigate = useNavigate();

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) {
        agTableStore.setState(data.data);
        setColumnSetting(data.data?.quickPrimaryColumnModel);
        setFilterSetting(data.data?.quickPrimaryFilterModel);
      }
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        quickPrimaryColumnModel: columnState,
        quickPrimaryFilterModel: filterState,
      },
    });
  };

  const componentsRegistry = useMemo(
    () => ({
      docName: memo(DocumentNameRender),
      fileType: memo(DocIconRender),
      tags: memo(TagsRender),
      size: memo(SizeRender),
      lastModified: memo(DateRender),
      link: memo(DownloadIconComponent),
    }),
    []
  );

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    mode?: "Add Transaction Document";
  }>({
    open: false,
  });

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        getQuickFilterText: (params) => params.data.reportDescription,
        headerName: "",
        field: "fileType",
        pinned: "left",
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        flex: 1,
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { paddingTop: "10px", lineHeight: "20px" },
        resizable: false,
        sortable: false,
        cellRenderer: "fileType",
      },
      {
        headerName: "Document Name",
        field: "docName",
        filter: "agSetColumnFilter",
        cellRenderer: "docName",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipDocName",
      },
      // {
      //   headerName: "Tags",
      //   field: "tags",
      //   filter: "agSetColumnFilter",
      //   cellRenderer: "tags",
      //   cellStyle: tableCellStyle,
      //   sortable: false,
      //   width: 250,
      //   maxWidth: 250,
      //   menuTabs: ["filterMenuTab"],
      // },
      {
        headerName: "Size",
        field: "size",
        filter: "agNumberColumnFilter",
        cellRenderer: "size",
        cellStyle: tableCellStyle,
        sortable: true,
        width: 150,
        // maxWidth: 150,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSize",
      },
      {
        headerName: "Last Modified",
        field: "lastModified",
        filter: "agNumberColumnFilter",
        cellRenderer: "lastModified",
        cellStyle: tableCellStyle,
        width: 150,
        // maxWidth: 150,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: `Action`,
        field: "link",
        pinned: "right",
        filter: "agStringColumnFilter",
        cellRenderer: "link",
        width: 80,
        cellStyle: { paddingTop: "10px", lineHeight: "20px" },
        menuTabs: ["filterMenuTab"],
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  const currencyType = getCurrencyType();
  const rowData = useMemo(
    () =>
      transactionDocuments?.map((document, index) => {
        setDisplayedRowCount(transactionDocuments?.length || 0);
        return {
          id: document.id,
          companyId,
          eventId: document.eventId,
          docName: document.name,
          tags: document.tags,
          size: document.size,
          lastModified: document.updatedAt,
          link: document.key,
          fileType: document.key?.split("/").at(-1),
          documentType: document.documentType,
          currencySymbol,
          currencyType,
        };
      }),
    [transactionDocuments]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.0) * 80;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.0) * 60;
    } else {
      return 300;
    }
  }

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };
  return (
    <div>
      <HStack className="flex-col items-start justify-start py-2 bg-white rounded-md lg:flex-row lg:justify-between lg:items-center">
        <div></div>
        <HStack className="items-center justify-end py-2 bg-white rounded-md">
          <HStack className="gap-4 px-4 py-2 ">
            <SearchInput
              placeholder={`Search`}
              onChange={(e: any) => {
                gridApi.current.api.setQuickFilter(e.target.value);
              }}
            />
            {!cId && (
            <PrimaryCTAButton
              event-name="Delete Confirmation"
              className="px-4"
              onClick={() => {
                setDialog({
                  open: true,
                  mode: "Add Transaction Document",
                });
              }}
              disabled={userRoleData[0].role === "Admin Viewer"}
            >
              <Icon
                icon="fluent:document-add-20-filled"
                width={18}
                height={18}
                className={`mr-1 border-transparent text-white bg-transparent`}
              />
              Add Document
            </PrimaryCTAButton>)}
            <Dialog open={dialog.open} maxWidth="md">
              {dialog.mode === "Add Transaction Document" ? (
                <AddTransactionDocument
                  onPrimaryAction={() => {
                    setDialog({ open: false });
                  }}
                  onSecondaryAction={() => {
                    setDialog({ open: false });
                  }}
                  mode="Add"
                  title="Add Transaction Document"
                  eventId={eventId}
                />
              ) : (
                <div></div>
              )}
            </Dialog>
            <IconCTAButton
              value={"Columns"}
              buttonSize={ButtonSize.SMALL}
              onClick={() => openToolPanel("columns")}
              iconName={"fluent:column-triple-edit-20-regular"}
              className={`px-4 font-medium items-center flex flex-row ${
                isColumnOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
            <IconCTAButton
              value={"Filters"}
              buttonSize={ButtonSize.SMALL}
              onClick={() => openToolPanel("filters")}
              iconName={"material-symbols:filter-alt"}
              className={`px-4 font-medium items-center flex flex-row ${
                isFilterOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
          </HStack>
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistry}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={ReactDOMServer.renderToString(
              <EmptyTable
                header="No Transaction Document Found"
                subHeader="Get started by adding some document"
              />
            )}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default TransactionDocumentsAgGridTable;
