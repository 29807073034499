import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import {
  CellClickedEvent,
  ColDef,
  SideBarDef,
  GridApi,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Box, HStack, VStack, useGetCompanyName } from "../../components/utils";
import { AgGridTransactionsCaptable } from "../../types/AllTransactionModel";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import {
  AmountInvestedRender,
  BuybackSharesRender,
  NetworthRender,
  NumberOfSharesRender,
  PostHoldingCommonSharesRender,
  PostHoldingDebRender,
  PostHoldingFdbPercentage,
  PostHoldingFdbSharesRender,
  PostHoldingPrefRender,
  PreHoldingCommonSharesRender,
  PreHoldingDebRender,
  PreHoldingFdbPercentage,
  PreHoldingFdbSharesRender,
  PreHoldingPrefRender,
  ShareholderNameRender,
  TotalAmountInvestedRender,
  TransactionAmountRender,
} from "./RoundsAGComponent";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import QuickSearch from "./QuickSearch";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import { useAuthStore } from "../../store/useAuthStore";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import {
  exportIndividualRoundSummaryReport,
  exportPAS4Report,
} from "../../api/Report";
import { getIndividualRoundDetailReport } from "../../api/Reports";
import { downloadExcel } from "../../utils/DownloadFile";
import { downloadFile } from "../../utils/downloadBlob";
import { tableCellStyle } from "../../components/TableComponent";
import { useAgGridTableState } from "../../store/agGridTableStore";
import { ExportImport } from "../Utility/GrantsTable";
import SearchInput from "../../components/shared/SearchInput";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import { useTableStateManagement } from "./TableHook";
import { TableId } from "../../constants/TableIdConstants";
import CloudSetting from "../../components/shared/CloudIcon";

function AGGridRoundsTable({
  capTableData,
  roundName,
  eventId,
  isInvestorPortal,
  mode,
}: {
  capTableData: AgGridTransactionsCaptable[];
  roundName: string;
  eventId: string;
  isInvestorPortal: boolean;
  mode: string;
}) {
  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const companyName = useGetCompanyName();

  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );

  const path = window.location.pathname.split("/") || [];
  const transactionId = path[path.length - 1] || '';
  const [pageLoaded, setPageLoaded] = useState(false);

  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
    tableState,
  } = useTableStateManagement(
    gridApi,
    `${TableId.transactionCaptable}${transactionId}`,
    pageLoaded
  );

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      switch (mode.toLocaleLowerCase()) {
        case "round":
          setColumnSetting(data.data?.roundsCaptableColumnModel);
          setFilterSetting(data.data?.roundsCaptableFilterModel);
          break;
        case "bonus":
          setColumnSetting(data.data?.bonusCaptableColumnModel);
          setFilterSetting(data.data?.bonusCaptableFilterModel);
          break;
        case "buyback":
          setColumnSetting(data.data?.buybackCaptableColumnModel);
          setFilterSetting(data.data?.buybackCaptableFilterModel);
          break;
        case "split":
          setColumnSetting(data.data?.splitCaptableColumnModel);
          setFilterSetting(data.data?.splitCaptableFilterModel);
          break;
        case "secondary":
          setColumnSetting(data.data?.splitCaptableColumnModel);
          setFilterSetting(data.data?.splitCaptableFilterModel);
          break;
        default:
      }
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    switch (mode.toLowerCase()) {
      case "round":
        postOnFilter({
          userId: `${userId}`,
          companyId,
          filterData: {
            ...agTableStore.state,
            roundsCaptableColumnModel: columnState,
            roundsCaptableFilterModel: filterState,
          },
        });
        break;
      case "bonus":
        postOnFilter({
          userId: `${userId}`,
          companyId,
          filterData: {
            ...agTableStore.state,
            bonusCaptableColumnModel: columnState,
            bonusCaptableFilterModel: filterState,
          },
        });
        break;
      case "buyback":
        postOnFilter({
          userId: `${userId}`,
          companyId,
          filterData: {
            ...agTableStore.state,
            buybackCaptableColumnModel: columnState,
            buybackCaptableFilterModel: filterState,
          },
        });
        break;
      case "split":
        postOnFilter({
          userId: `${userId}`,
          companyId,
          filterData: {
            ...agTableStore.state,
            splitCaptableColumnModel: columnState,
            splitCaptableFilterModel: filterState,
          },
        });
        break;
      case "secondary":
        postOnFilter({
          userId: `${userId}`,
          companyId,
          filterData: {
            ...filterState,
            secondaryCaptableColumnModel: columnState,
            secondaryCaptableFilterModel: filterState,
          },
        });
        break;
      default:
    }
  };

  const cin = "";
  const componentsRegistery = useMemo(
    () => ({
      shareholderName: memo(ShareholderNameRender),
      preHoldingFdbShares: memo(PreHoldingFdbSharesRender),
      postHoldingFdbShares: memo(PostHoldingFdbSharesRender),
      preHoldingFdbPercentage: memo(PreHoldingFdbPercentage),
      postHoldingFdbPercentage: memo(PostHoldingFdbPercentage),
      amountInvested: memo(AmountInvestedRender),
      totalAmountInvested: memo(TotalAmountInvestedRender),
      numberOfShares: memo(NumberOfSharesRender),
      buybackShares: memo(BuybackSharesRender),
      networth: memo(NetworthRender),
      transactionAmount: memo(TransactionAmountRender),
      preHoldingCommonShares: memo(PreHoldingCommonSharesRender),
      postHoldingCommonShares: memo(PostHoldingCommonSharesRender),
      preHoldingPref: memo(PreHoldingPrefRender),
      postHoldingPref: memo(PostHoldingPrefRender),
      preHoldingDeb: memo(PreHoldingDebRender),
      postHoldingDeb: memo(PostHoldingDebRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    if (!isInvestorPortal) {
      handleEventForTracking({ eventName: `Shareholder Name` });
      navigate(`/shareholders/individual/${id}`);
    }
  };

  const handleActions = async (action: Action) => {
    if (action.name === "Round Summary Report") {
      exportIndividualRoundSummaryReport(eventId).then((res) => {
        const fileName = `Round Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
      //Dirty Report Commit const data = await exportRoundSummaryReport();
      // downloadExcel(data?.data?.data, "Round Summary Report");
    } else if (action.name === "Round Details Report") {
      getIndividualRoundDetailReport(roundName).then((res) => {
        const fileType = res.headers.filetype;
        const fileName = `${res.headers.filename}`;
        downloadFile(res.data, fileName, fileType);
      });
      //Dirty Report Commit const data = await exportRoundBasedReport();
      // downloadExcel(data?.data?.data, "Round Details Report");
    } else if (action.name === "Export Pas4 Report") {
      exportPAS4Report(eventId, roundName).then((res) => {
        const fileName = `Pas4_Report_${roundName}`;
        downloadExcel(res, fileName);
      });
      //Dirty Report Commit const data = await exportPAS4Report();
      // downloadExcel(data?.data?.data, "PAS4 Report");
    }
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: any[] = useMemo<any>(() => {
    if (mode.toLowerCase() === "round") {
      return [
        {
          headerName: "Name",
          field: "shareholderName",
          getQuickFilterText: (params: any) => params.value.name,
          filter: "agMultiColumnFilter",
          comparator(valueA: any, valueB: any, ..._: any[]) {
            return valueA.name > valueB.name ? 1 : -1;
          },
          filterParams: {
            maxNumConditions: 5,
            buttons: ["reset"],
            filters: [
              {
                title: "Name",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.name,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("shareholderName").name,
                },
              },
              {
                title: "Type",
                filter: "agSetColumnFilter",
                display: "subMenu",
                buttons: ["reset"],
                filterParams: {
                  buttons: ["reset"],
                  keyCreator: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                  valueFormatter: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                },
              },
            ],
          },
          cellRenderer: "shareholderName",
          initialWidth: 300,
          sortable: true,
          autoHeight: true,
          cellStyle: tableCellStyle,
          wrapText: true,
          suppressSizeToFit: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipShareholderName",
          pinned: "left",
        },
        {
          headerName: "Pre-Holding FDB Shares",
          field: "preHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingFDBShares",
        },
        {
          headerName: "Post-Holding FDB Shares",
          field: "postHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingFDBShares",
        },
        {
          headerName: "Pre-Holding FDB %",
          field: "preHoldingFdbPercentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingFdbPercentage",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingFdbPercentage",
        },
        {
          headerName: "Post-Holding FDB %",
          field: "postHoldingFdbPercentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingFdbPercentage",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingFdbPercentage",
        },
        {
          headerName: `Amount Invested (${currencySymbol})`,
          field: "amountInvested",
          filter: "agMultiColumnFilter",
          cellRenderer: "amountInvested",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipAmountInvested",
          filterParams: {
            maxNumConditions: 5,
            buttons: ["reset"],
            filters: [
              {
                title: "Amount Invested",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.amount,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("amountInvested").amount,
                },
              },
              {
                title: "Amount in Round Currency",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.type,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("amountInvested").type,
                },
              },
            ],
          },
        },
        {
          headerName: `Total Amount Invested (${currencySymbol})`,
          field: "totalAmountInvested",
          filter: "agNumberColumnFilter",
          cellRenderer: "totalAmountInvested",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipTotalAmountInvested",
        },
      ];
    } else if (mode.toLowerCase() === "bonus") {
      return [
        {
          headerName: "Name",
          field: "shareholderName",
          getQuickFilterText: (params: any) => params.value.name,
          filter: "agMultiColumnFilter",
          comparator(valueA: any, valueB: any, ..._: any[]) {
            return valueA.name > valueB.name ? 1 : -1;
          },
          filterParams: {
            maxNumConditions: 5,
            buttons: ["reset"],
            filters: [
              {
                title: "Name",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.name,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("shareholderName").name,
                },
              },
              {
                title: "Type",
                filter: "agSetColumnFilter",
                display: "subMenu",
                filterParams: {
                  buttons: ["reset"],
                  keyCreator: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                  valueFormatter: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                },
              },
            ],
          },
          cellRenderer: "shareholderName",
          initialWidth: 300,
          sortable: true,
          autoHeight: true,
          cellStyle: tableCellStyle,
          wrapText: true,
          suppressSizeToFit: true,
          pinned: "left",
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipShareholderName",
        },
        {
          headerName: "Pre-Holding Common Shares",
          field: "preHoldingCommonShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingCommonShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingCommonShares",
        },
        {
          headerName: "Post-Holding Common Shares",
          field: "postHoldingCommonShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingCommonShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingCommonShares",
        },
        {
          headerName: "Pre-Holding Pref",
          field: "preHoldingPref",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingPref",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingPref",
        },
        {
          headerName: "Post-Holding Pref",
          field: "postHoldingPref",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingPref",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingPref",
        },
        {
          headerName: "Pre-Holding Debentures",
          field: "preHoldingDeb",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingDeb",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingDeb",
        },

        {
          headerName: "Post-Holding Debentures",
          field: "postHoldingDeb",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingDeb",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingDeb",
        },
        {
          headerName: "Pre-Holding FDB Shares",
          field: "preHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingFDBShares",
        },
        {
          headerName: "Post-Holding FDB Shares",
          field: "postHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingFDBShares",
        },
        {
          headerName: "Pre-Holding FDB %",
          field: "preHoldingFdbPercentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingFdbPercentage",
          cellStyle: tableCellStyle,
          sortable: true,
          tooltipField: "tooltipPreHoldingFdbPercentage",
          menuTabs: ["filterMenuTab"],
        },
        {
          headerName: "Post-Holding FDB %",
          field: "postHoldingFdbPercentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingFdbPercentage",
          cellStyle: tableCellStyle,
          sortable: true,
          tooltipField: "tooltipPostHoldingFdbPercentage",
          menuTabs: ["filterMenuTab"],
        },
      ];
    } else if (mode.toLowerCase() === "buyback") {
      return [
        {
          headerName: "Name",
          field: "shareholderName",
          filter: "agMultiColumnFilter",
          getQuickFilterText: (params: any) => params.value.name,
          comparator(valueA: any, valueB: any, ..._: any[]) {
            return valueA.name > valueB.name ? 1 : -1;
          },
          filterParams: {
            maxNumConditions: 5,
            buttons: ["reset"],
            filters: [
              {
                title: "Name",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.name,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("shareholderName").name,
                },
              },
              {
                title: "Type",
                filter: "agSetColumnFilter",
                display: "subMenu",
                buttons: ["reset"],
                filterParams: {
                  buttons: ["reset"],
                  keyCreator: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                  valueFormatter: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                },
              },
            ],
          },
          cellRenderer: "shareholderName",
          initialWidth: 300,
          sortable: true,
          autoHeight: true,
          cellStyle: tableCellStyle,
          wrapText: true,
          suppressSizeToFit: true,
          menuTabs: ["filterMenuTab"],
          pinned: "left",
          tooltipField: "tooltipShareholderName",
        },
        {
          headerName: "Pre-Holding FDB Shares",
          field: "preHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingFDBShares",
        },
        {
          headerName: "Post-Holding FDB Shares",
          field: "postHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingFDBShares",
        },
        {
          headerName: "Pre-Holding FDB %",
          field: "preHoldingFdbPercentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingFdbPercentage",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingFdbPercentage",
        },
        {
          headerName: "Post-Holding FDB %",
          field: "postHoldingFdbPercentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingFdbPercentage",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingFdbPercentage",
        },
        {
          headerName: "Buyback Shares",
          field: "buybackShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "buybackShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipBuybackShares",
        },
      ];
    } else if (mode.toLowerCase() === "split") {
      return [
        {
          headerName: "Name",
          field: "shareholderName",
          filter: "agMultiColumnFilter",
          getQuickFilterText: (params: any) => params.value.name,
          comparator(valueA: any, valueB: any, ..._: any[]) {
            return valueA.name > valueB.name ? 1 : -1;
          },
          filterParams: {
            maxNumConditions: 5,
            buttons: ["reset"],
            filters: [
              {
                title: "Name",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.name,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("shareholderName").name,
                },
              },
              {
                title: "Type",
                filter: "agSetColumnFilter",
                display: "subMenu",
                filterParams: {
                  buttons: ["reset"],
                  keyCreator: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                  valueFormatter: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                },
              },
            ],
          },
          cellRenderer: "shareholderName",
          initialWidth: 300,
          sortable: true,
          autoHeight: true,
          cellStyle: tableCellStyle,
          wrapText: true,
          suppressSizeToFit: true,
          pinned: "left",
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipShareholderName",
        },
        {
          headerName: "Pre-Holding Common Shares",
          field: "preHoldingCommonShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingCommonShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingCommonShares",
        },
        {
          headerName: "Post-Holding Common Shares",
          field: "postHoldingCommonShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingCommonShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingCommonShares",
        },
        {
          headerName: "Pre-Holding Pref",
          field: "preHoldingPref",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingPref",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingPref",
        },
        {
          headerName: "Post-Holding Pref",
          field: "postHoldingPref",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingPref",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingPref",
        },
        {
          headerName: "Pre-Holding FDB Shares",
          field: "preHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingFDBShares",
        },
        {
          headerName: "Post-Holding FDB Shares",
          field: "postHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingFDBShares",
        },
      ];
    } else if (mode.toLowerCase() === "secondary") {
      return [
        {
          headerName: "Name",
          field: "shareholderName",
          getQuickFilterText: (params: any) => params.value.name,
          filter: "agMultiColumnFilter",
          comparator(valueA: any, valueB: any, ..._: any[]) {
            return valueA.name > valueB.name ? 1 : -1;
          },
          filterParams: {
            maxNumConditions: 5,
            buttons: ["reset"],
            filters: [
              {
                title: "Name",
                display: "subMenu",
                filter: "agTextColumnFilter",
                buttons: ["reset"],
                filterValueGetter: (params: any) => params.value.name,
                filterParams: {
                  buttons: ["reset"],
                  valueGetter: (params: any) =>
                    params.getValue("shareholderName").name,
                },
              },
              {
                title: "Type",
                filter: "agSetColumnFilter",
                display: "subMenu",
                buttons: ["reset"],
                filterParams: {
                  buttons: ["reset"],
                  keyCreator: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                  valueFormatter: (params: any) => {
                    const shareholderType = params.value.type;
                    return shareholderType;
                  },
                },
              },
            ],
          },
          cellRenderer: "shareholderName",
          initialWidth: 300,
          sortable: true,
          autoHeight: true,
          cellStyle: tableCellStyle,
          wrapText: true,
          suppressSizeToFit: true,
          menuTabs: ["filterMenuTab"],
          pinned: "left",
          tooltipField: "tooltipShareholderName",
        },
        {
          headerName: "Pre-Holding FDB Shares",
          field: "preHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingFDBShares",
        },
        {
          headerName: "Post-Holding FDB Shares",

          field: "postHoldingFdbShares",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingFdbShares",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingFDBShares",
        },
        {
          headerName: "Pre-Holding FDB %",

          field: "preHoldingFdbPercentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "preHoldingFdbPercentage",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPreHoldingFdbPercentage",
        },
        {
          headerName: "Post-Holding FDB %",
          field: "postHoldingFdbPercentage",
          filter: "agNumberColumnFilter",
          cellRenderer: "postHoldingFdbPercentage",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipPostHoldingFdbPercentage",
        },
        {
          headerName: `Transaction Amount (${currencySymbol})`,
          field: "transactionAmount",
          filter: "agNumberColumnFilter",
          cellRenderer: "transactionAmount",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipTransactionAmount",
        },
        {
          headerName: `Total Amount Invested (${currencySymbol})`,
          field: "totalAmountInvested",
          filter: "agNumberColumnFilter",
          cellRenderer: "totalAmountInvested",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipTotalAmountInvested",
          hide: true,
        },
        {
          headerName: `Net Worth (${currencySymbol})`,
          field: "networth",
          filter: "agNumberColumnFilter",
          cellRenderer: "networth",
          cellStyle: tableCellStyle,
          sortable: true,
          menuTabs: ["filterMenuTab"],
          tooltipField: "tooltipNetworth",
          hide: true,
        },
      ];
    } else return [];
  }, [mode]);

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();
  const rowData = useMemo(() => {
    setPageLoaded(true);
    return capTableData?.map((template) => ({
      id: template.id,
      rounds: template.rounds,
      preHoldingFdbShares: template.preHoldingFdbShares,
      tooltipPreHoldingFDBShares:
        template.preHoldingFdbShares?.toLocaleString(currencyType),
      preHoldingFdbPercentage: Number(
        (Math.abs(template.preHoldingFdbPercentage) * 100).toFixed(2)
      ),
      tooltipPreHoldingFdbPercentage:
        Math.abs(template.preHoldingFdbPercentage) * 100,

      postHoldingFdbShares: template.postHoldingFdbShares,
      tooltipPostHoldingFDBShares:
        template.postHoldingFdbShares?.toLocaleString(currencyType),
      postHoldingFdbPercentage: Number(
        (Math.abs(template.postHoldingFdbPercentage) * 100).toFixed(2)
      ),
      tooltipPostHoldingFdbPercentage: (
        Math.abs(template.postHoldingFdbPercentage) * 100
      ).toLocaleString(),
      amountInvested: {
        amount: template.amountInvestedInRound,
        type: template.amountInvestedInCurrency! * template.exchangeRate!,
        roundCurrency: template.roundCurrency,
      },
      tooltipAmountInvested:
        template.amountInvestedInRound?.toLocaleString(currencyType),
      totalAmountInvested: template.totalAmountInvested,
      tooltipTotalAmountInvested:
        template.totalAmountInvested?.toLocaleString(currencyType),
      shareholderName: {
        name: template.shareholderName,
        type: template.shareholderType,
      },
      tooltipShareholderName: template.shareholderName,
      shareholderType: template.shareholderType,
      numberOfShares: template.numberOfShares,
      tooltipNoOfShares: template.numberOfShares?.toLocaleString(currencyType),
      buybackShares:
        template.preHoldingFdbShares - template.postHoldingFdbShares,
      toolTipBuybackShares: (
        template.preHoldingFdbShares - template.postHoldingFdbShares
      )?.toLocaleString(currencyType),
      networth: template.netWorth,
      tooltipNetworth: template.netWorth?.toLocaleString(currencyType),
      transactionAmount: template.transactionValue,
      tooltipTransactionAmount:
        template.transactionValue?.toLocaleString(currencyType),
      preHoldingCommonShares: template.preHoldingCommonShares,
      tooltipPreHoldingCommonShares:
        template.preHoldingCommonShares?.toLocaleString(currencyType),
      postHoldingCommonShares: template.postHoldingCommonShares,
      tooltipPostHoldingCommonShares:
        template.postHoldingCommonShares?.toLocaleString(currencyType),
      preHoldingPref: template.preHoldingPrefShares,
      tooltipPreHoldingPref:
        template.preHoldingPrefShares?.toLocaleString(currencyType),
      postHoldingPref: template.postHoldingPrefShares,
      tooltipPostHoldingPref:
        template.postHoldingPrefShares?.toLocaleString(currencyType),
      preHoldingDeb: template.preHoldingDebentures,
      tooltipPreHoldingDeb:
        template.preHoldingDebentures?.toLocaleString(currencyType),
      postHoldingDeb: template.postHoldingDebentures,
      tooltipPostHoldingDeb:
        template.postHoldingDebentures?.toLocaleString(currencyType),

      currencySymbol,
      currencyType,
    }));
  }, [capTableData]);

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.68) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.48) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.42) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (
        cellParams.value.name !== "Total" &&
        cellParams.column.getColId() === "shareholderName"
      ) {
        onClickShareholderName(template.id);
      }
    }
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalPreholdingFdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingFdbShares,
      0
    );

    const totalPostholdingFdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingFdbShares,
      0
    );

    const totalAmountInvest = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.totalAmountInvested,
      0
    );

    const amountInvest = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested.amount,
      0
    );

    const totalNumberOfShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.numberOfShares,
      0
    );

    const totalNetWorth = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.netWorth,
      0
    );

    const totalTransactionAmount = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.transactionValue,
      0
    );

    const totalPreHoldingCommonShares = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.preHoldingCommonShares,
      0
    );

    const totalPostholdingCommonShares = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.postHoldingCommonShares,
      0
    );

    const totalpreholdinngPrefShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingPrefShares,
      0
    );

    const totalPostholdingPrefShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingPrefShares,
      0
    );

    const totalPreholdingDeb = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingDebentures,
      0
    );

    const totalPostholdingDeb = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingDebentures,
      0
    );

    const totalPreholdingFdbPercentage = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.preHoldingFdbPercentage,
      0
    );

    const totalPostholdingFdbPercentage = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.preHoldingFdbPercentage,
      0
    );

    api.setPinnedBottomRowData([
      {
        shareholderName: {
          name: "Total",
          type: null,
        },

        rounds: null,
        preHoldingFdbShares: totalPreholdingFdbShares,
        tooltipPreHoldingFDBShares:
          totalPreholdingFdbShares.toLocaleString(currencyType),
        preHoldingFdbPercentage:
          totalPreholdingFdbPercentage * 100 > 99.98
            ? 100
            : Number((totalPreholdingFdbPercentage * 100).toFixed(2)),
        tooltipPreHoldingFdbPercentage:
          totalPreholdingFdbPercentage * 100 > 99.98
            ? 100
            : Number((totalPreholdingFdbPercentage * 100).toFixed(2)),

        postHoldingFdbShares: totalPostholdingFdbShares,
        tooltipPostHoldingFDBShares:
          totalPostholdingFdbShares.toLocaleString(currencyType),
        postHoldingFdbPercentage: Number(
          totalPostholdingFdbPercentage * 100 > 99.98
            ? 100
            : Number((totalPostholdingFdbPercentage * 100).toFixed(2))
        ),
        tooltipPostHoldingFdbPercentage:
          totalPostholdingFdbPercentage * 100 > 99.98
            ? 100
            : Number((totalPostholdingFdbPercentage * 100).toFixed(2)),

        amountInvested: { amount: amountInvest, type: "-" },
        tooltipAmountInvested: amountInvest?.toLocaleString(currencyType),
        totalAmountInvested: totalAmountInvest,
        tooltipTotalAmountInvested:
          totalAmountInvest?.toLocaleString(currencyType),

        numberOfShares: totalNumberOfShares,
        tooltipNoOfShares: totalNumberOfShares.toLocaleString(currencyType),
        networth: totalNetWorth,
        tooltipNetworth: totalNetWorth?.toLocaleString(currencyType),
        transactionAmount: totalTransactionAmount,
        tooltipTransactionAmount:
          totalTransactionAmount?.toLocaleString(currencyType),
        preHoldingCommonShares: totalPreHoldingCommonShares,
        tooltipPreHoldingCommonShares:
          totalPreHoldingCommonShares?.toLocaleString(currencyType),
        postHoldingCommonShares: totalPostholdingCommonShares,
        tooltipPostHoldingCommonShares:
          totalPostholdingCommonShares?.toLocaleString(currencyType),
        preHoldingPref: totalpreholdinngPrefShares,
        tooltipPreHoldingPref:
          totalpreholdinngPrefShares?.toLocaleString(currencyType),
        postHoldingPref: totalPostholdingPrefShares,
        tooltipPostHoldingPref:
          totalPostholdingPrefShares?.toLocaleString(currencyType),
        preHoldingDeb: totalPreholdingDeb,
        tooltipPreHoldingDeb: totalPreholdingDeb?.toLocaleString(currencyType),
        postHoldingDeb: totalPostholdingDeb,
        tooltipPostHoldingDeb:
          totalPostholdingDeb?.toLocaleString(currencyType),
        currencyType,
      },
    ]);
  }

  const [columnState, setColumnState] = useState(null);

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    uploadFilterAndColumn();
    uploadFilter();
  };

  useEffect(() => {
    const filterModel = gridApi.current?.api.getFilterModel();

    const filteredData = gridApi.current?.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);

    setFilteredRowData(filteredData);
    gridApi.current?.api.setFilterModel(tableState?.filterState);
  }, [gridApi, tableState?.filterState, tableState?.columnState, capTableData]);

  return (
    <div className="border border-borderColor bg-white shadow-box">
      <HStack className="flex-col lg:flex-row justify-start lg:justify-between  py-2 lg:items-center items-start  bg-white rounded-md">
        <GenericTableHeader
          heading={"CapTable"}
          subHeading={"Shareholders"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="justify-end py-2 items-center gap-4 px-4">
          <CloudSetting
            tableId={`${TableId.transactionCaptable}${transactionId}`}
            isLoading={isSaving}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
          />
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          {!isInvestorPortal && (
            <div className="md:block">
              <ExportImport
                actions={[
                  {
                    name: "Round Summary Report",
                  },
                  {
                    name: "Round Details Report",
                    disabled: true,
                  },
                  {
                    name: "Export Pas4 Report",
                  },
                ]}
                onAction={(action) => handleActions(action)}
              />
            </div>
          )}
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            onColumnMoved={uploadColumn}
            rowData={rowData}
            enableCharts={true}
            enableRangeSelection={true}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AGGridRoundsTable;
