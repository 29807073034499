import { useMemo } from "react";
import { Icon } from "@iconify/react";
import { tableEntryStyle } from "../../components/TableComponent";
import { HStack, VStack } from "../../components/utils";
import { formatDisplayDate } from "../../utils/date";
import { getTagsColor } from "./DirectorAgComponent";

export function DirectorNamesRender(data: any) {
  return (
    <div className="flex flex-wrap gap-2 mb-2">
      {data.data.directorName
        ?.sort((a: any, b: any) => {
          const attended = data.data.attendedInvestors || [];
          return attended.includes(b.name) - attended.includes(a.name);
        })
        ?.map((director: any, index: number) => (
          <>
          {index < 2 && (
          <div
            key={index}
            className={`text-xs text-gray-600 text-center px-3 py-1 align-middle font-semibold leading-5 border border-1 border-gray-600 rounded-md ${
              data.data.attendedInvestors.includes(director.name)
                ? "border-primary"
                : ""
            }`}
          >
            <HStack
              className={`${
                data.data.attendedInvestors.includes(director.name)
                  ? "text-primary"
                  : ""
              }`}
            >
              <Icon
                icon="si:user-fill"
                width={18}
                height={18}
                className={`mr-1 border-transparent bg-transparent ${
                  data.data.attendedInvestors.includes(director.name)
                    ? "text-primary"
                    : ""
                }`}
              />
              {director.name}
            </HStack>
          </div>
          )}
          {data.data.directorName.length > 2 && index === data.data.directorName.length - 1 &&

          <div
            key={index}
            className={`text-xs text-gray-600 text-center px-2 py-1 align-middle font-semibold leading-5 border border-1 border-gray-600 rounded-md ${
              data.data.attendedInvestors.includes(director.name)
                ? "border-primary"
                : ""
            }`}
          >
            + {data.data.directorName.length - 2}
          </div>

          }
          </>
        ))}
    </div>
  );
}

export function DateOfMeetingRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data.dateOfMeeting)}
      </p>
    </div>
  );
}
export function DirectorAttendedRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{data.data.noOfDirectorAttended}</p>
    </div>
  );
}
export function NameRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={"font-bold"}>{data.data.name}</p>
    </div>
  );
}

export function TagsRender(data: any) {
  return (
    <div className="flex flex-nowrap items-center gap-2 overflow-x-auto scrollbar-hide pt-2">
      {data.data.tags?.map((tag: string, index: number) => (
        <div
          key={index}
          className={`${getTagsColor(
            tag,
            index
          )} text-xs text-center px-3 font-semibold leading-5 rounded-md`}
        >
          {tag}
        </div>
      ))}
    </div>
  );
}

export function DirectorToolTip(props: any) {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div className={`relative p-2 shadow-xl group ${data?.directorName.length > 2 ? 'bg-white' : 'bg-transparent'}`}>
      {data?.directorName && (
        <div
          className={`${
            data.directorName?.length <= 4
              ? "custom-tooltip scrollbar-hide"
              : "custom-tooltip max-h-24 overflow-y-scroll"
          }`}
        >
          {data.directorName
        ?.sort((a: any, b: any) => {
          const attended = data.attendedInvestors || [];
          return attended.includes(b.name) - attended.includes(a.name);
        })
        ?.map((director: any, index: number) => (
          <>
            {index > 1 && <p className="p-1" key={director.id}>
              <span>
                <HStack
                  className={`${
                    data.attendedInvestors.includes(director.name)
                      ? "text-primary"
                      : ""
                  }`}
                >
                  <Icon
                    icon="si:user-fill"
                    width={18}
                    height={18}
                    className={`mr-1 border rounded-xl bg-transparent ${
                      data.attendedInvestors.includes(director.name)
                        ? "text-primary border-primary"
                        : "text-gray-600"
                    }`}
                  />
                  {director.name}
                </HStack>
              </span>
            </p>}
            </>
          ))}
        </div>
      )}
    </div>
  );
  // return <></>
}
