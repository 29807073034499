import { useFormikContext } from "formik";
import Select, { ActionMeta, MultiValue } from "react-select";
import { useEffect, useRef, useState } from "react";
import { Input, Label, Required, TextArea } from "../../components/shared/InputField";
import {
  Box,
  BoxContainer,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import {
  CompanyDetailsPostModel,
  DropDownModel,
} from "../../types/OnBoardDataModel";
import MultipleDropDown from "./MultiDropDown";
import { Headings } from "../../components/Headings";
import AnimatedMultiSelect from "../../components/shared/MultiDropDownSelect";

type BusinessDetailFormDetail = {
  dropDownValue: DropDownModel;
};

export default function BusinessDetailForm({
  dropDownValue,
}: BusinessDetailFormDetail) {
  const formik = useFormikContext<CompanyDetailsPostModel>();
  const { errors, touched, getFieldProps } = formik;
  const [ind, setInd] = useState<any>();
  const [cat, setCat] = useState<any>();

  useEffect(() => {
    const defaultVal = formik.values.category
      ? formik.values.category.map((ele: any) => ({
          value: ele,
          label: ele,
        }))
      : undefined;
    setCat(defaultVal);
  }, [formik.values.category]);
  useEffect(() => {
    const defaultVal = formik.values.industry
      ? formik.values.industry.map((ele: any) => ({
          value: ele,
          label: ele,
        }))
      : undefined;
    setInd(defaultVal);
  }, [formik.values.industry]);

  const onIndustryChange = (ind: any) => {
    const data = ind.map((ele: any) => ele.value);
    formik.setFieldValue("industry", data);
  };
  const onCategoryChange = (ind: any) => {
    const data = ind.map((ele: any) => ele.value);
    formik.setFieldValue("category", data);
  };

  return (
    <BoxContainer className="z-10">
      <Headings text={"Business Details"} />
      <VStack className="w-full gap-8">
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
          <div className="flex-1">
            <Label>
              Category
              <Required />
            </Label>
            <AnimatedMultiSelect
              value={cat}
              hasError={touched.category && errors.category?.length === 0}
              onChange={onCategoryChange}
              options={dropDownValue.categoryDropdownValues.map((element) => ({
                value: element,
                label: element,
              }))}
            />
            {touched.category && errors.category && (
              <Error text={errors.category as string} />
            )}
          </div>

          <div className="flex-1">
            <Label>
              Industry
              <Required />
            </Label>

            <AnimatedMultiSelect
              value={ind}
              hasError={touched.industry && errors.industry?.length === 0}
              onChange={onIndustryChange}
              options={dropDownValue.industryDropdownValues.map((element) => ({
                value: element,
                label: element,
              }))}
            />
            {touched.industry && errors.industry && (
              <Error text={errors.industry as string} />
            )}
          </div>
        </HStack>
        <HStack className="sm:gap-8 gap-4 sm:flex-row flex-col">
          <div className="flex-1">
            <Label>
              NIC Code
              <Required />
            </Label>
            <Input
              type="text"
              className="W-120"
              placeholder="NIC Code"
              {...getFieldProps("nic")}
            />

            {touched.nic && errors.nic && <Error text={errors.nic} />}
          </div>
          <div className="flex-1">
            <Label>Doing Business as</Label>

            <Input
              className="w-120"
              type="text"
              {...getFieldProps("doingBusinessAs")}
            />
            {touched.doingBusinessAs && errors.doingBusinessAs && (
              <Error text={errors.doingBusinessAs} />
            )}
          </div>
        </HStack>
        <div className="flex-1">
                    <Label>
                      {'Business Description'}
                    </Label>
                    <TextArea
                      placeholder="Enter Business Description"
                      {...getFieldProps("businessDescription")}
                    />
                  </div>
      </VStack>
    </BoxContainer>
  );
}
