import { useEffect, useState } from "react";
import _ from "lodash";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { HStack, Error, VStack } from "../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { CapitalDetails, PpsAndValuation } from "../types/Shareholder";
import { Input, Label } from "../components/shared/InputField";
import { useUpdatePpsAndValuation } from "../queries/transactionRound";
import { useAuthStore } from "../store";
import { Select } from "../components/shared/Select";
import { getCurrencySymbol, getCurrencyType } from "../utils/currencyFormatter";
import { ShowNumberInWords } from "../components/shared/UiElement";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../utils/numUtils";
import { queryClient } from "../queries";

type PpsAndValuationEdit = {
  pps: number;
  valuation: number;
  eventId: string;
  paymentType: string | null;
  issuanceType: string | null;
  authorizedEquity?: number | null;
  authorizedPreference?: number | null;
  onSecondaryAction: () => void;
};

export function EditPpsAndValuation({
  pps,
  valuation,
  eventId,
  paymentType,
  issuanceType,
  authorizedEquity,
  authorizedPreference,
  onSecondaryAction = () => {},
}: PpsAndValuationEdit) {
  const { companyId } = useAuthStore.getState();
  const { mutate: ppsValuation } = useUpdatePpsAndValuation();
  const ppsValuationSchema = Yup.object().shape({
    valuation: Yup.number().required().label("Valuation"),
  });
  const currencySymbol = getCurrencySymbol();

  const [onHoverValuation, setOnHoverValuation] = useState(false);
  const [onHoverEquity, setOnHoverEquity] = useState(false);
  const [onHoverPreference, setOnHoverPreference] = useState(false);
  const [formatValuation, setFormatValuation] = useState("");

  useEffect(() => {
    if (valuation) setFormatValuation(`${valuation}`);
  }, [valuation]);
  const initialValues: CapitalDetails = {
    pps: pps || 0,
    valuation: valuation || 0,
    eventId: eventId || "",
    paymentType: paymentType || null,
    issuanceType: issuanceType || null,
    authorizedEquity: authorizedEquity || 0,
    authorizedPreference: authorizedPreference || 0,
  };
  const currencyType = getCurrencyType();
  return (
    <div className="w-full p-2">
      <HStack className="justify-between border-b px-4 py-4 items-center">
        <p className="font-semibold text-lg">Update PPS And Valuation</p>
        <XMarkIcon
          className="cursor-pointer h-6"
          onClick={() => onSecondaryAction()}
        />
      </HStack>
      <Formik
        enableReinitialize={true}
        validationSchema={ppsValuationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          const capitalDetails: PpsAndValuation = {
            companyId: companyId || "",
            capitalDetails: [values],
          };
          ppsValuation(capitalDetails, {
            onSuccess: () => {
              queryClient.invalidateQueries("getRoundTransactions");
              queryClient.refetchQueries("getRoundTransactions");
              toast("Round Detail Updated Successfully", {
                type: "success",
                autoClose: 2000,
              });
              onSecondaryAction();
            },
            onError: (err: any) => {
              toast(err.response.errorMessage, {
                type: "error",
                autoClose: 2000,
              });
              onSecondaryAction();
            },
          });
        }}
      >
        {(formik) => (
          <Form>
            <VStack className="p-4 gap-4">
              <HStack className="justify-between items-center w-96">
                <Label className="text-sm font-normal items-center whitespace-nowrap w-32">
                  Valuation {`(${currencySymbol})`}
                </Label>
                <VStack className="w-60">
                  <Input
                    type="text"
                    placeholder=""
                    className="w-60"
                    onMouseEnter={() => {
                      setOnHoverValuation(true);
                    }}
                    onMouseLeave={() => {
                      setOnHoverValuation(false);
                    }}
                    onChange={(e: any) => {
                      const filteredValue = filterOnlyNumbers(e.target.value);
                      setFormatValuation(filteredValue);
                      const values = convertToNumber(filteredValue);

                      formik.setFieldValue("valuation", values);
                      formik.handleChange("valuation");
                    }}
                    value={numberWithCommas(formatValuation, currencyType)}
                  />
                  {onHoverValuation && (
                    <ShowNumberInWords
                      value={formik.values.valuation!}
                      width={38}
                      currency={currencyType}
                    />
                  )}
                  {formik.touched?.valuation && formik.errors?.valuation && (
                    <Error text={formik.errors?.valuation} />
                  )}
                </VStack>
              </HStack>
              <HStack className="justify-between items-center w-96">
                <Label className="text-sm font-normal items-center  whitespace-nowrap w-32">
                  Payment Type
                </Label>
                <VStack className="w-60">
                  <Select
                    options={["Cash", "Non Cash"]}
                    className="w-60"
                    {...formik.getFieldProps("paymentType")}
                  />
                  {formik.touched?.paymentType &&
                    formik.errors?.paymentType && (
                      <Error text={formik.errors?.paymentType} />
                    )}
                </VStack>
              </HStack>
              <HStack className="justify-between items-center w-96">
                <Label className="text-sm font-normal items-center  whitespace-nowrap w-32">
                  Issuance Type
                </Label>
                <VStack className="w-60">
                  <Select
                    options={[
                      "Private Placement",
                      "Rights Issues",
                      "Preferential Allotment",
                    ]}
                    className="w-60"
                    {...formik.getFieldProps("issuanceType")}
                  />
                  {formik.touched?.issuanceType &&
                    formik.errors?.issuanceType && (
                      <Error text={formik.errors?.issuanceType} />
                    )}
                </VStack>
              </HStack>
              <HStack className="justify-between items-center w-96">
                <Label className="text-sm font-normal items-center whitespace-nowrap w-32">
                  Authorized Equity {`(${currencySymbol})`}
                </Label>
                <VStack className="w-60">
                <Input
                    type="text"
                    placeholder=""
                    className="w-60"
                    onMouseEnter={() => {
                      setOnHoverEquity(true);
                    }}
                    onMouseLeave={() => {
                      setOnHoverEquity(false);
                    }}
                    onChange={(e: any) => {
                      const filteredValue = filterOnlyNumbers(e.target.value);
                      // setFormatValuation(filteredValue);
                      const values = convertToNumber(filteredValue);

                      formik.setFieldValue("authorizedEquity", values);
                      formik.handleChange("authorizedEquity");
                    }}
                    value={numberWithCommas((formik.values.authorizedEquity || '')?.toString(), currencyType)}
                  />
                  {onHoverEquity && (
                    <ShowNumberInWords
                      value={formik.values.authorizedEquity!}
                      width={38}
                      currency={currencyType}
                    />
                  )}
                  {formik.touched?.authorizedEquity &&
                    formik.errors?.authorizedEquity && (
                      <Error text={formik.errors?.authorizedEquity} />
                    )}
                </VStack>
              </HStack>
              <HStack className="justify-between items-center gap-2 w-96">
                <Label className="text-sm font-normal items-center w-20">
                Authorized Preference {`(${currencySymbol})`}
                </Label>
                <VStack className="w-60">
                <Input
                    type="text"
                    placeholder=""
                    className="w-60"
                    onMouseEnter={() => {
                      setOnHoverPreference(true);
                    }}
                    onMouseLeave={() => {
                      setOnHoverPreference(false);
                    }}
                    onChange={(e: any) => {
                      const filteredValue = filterOnlyNumbers(e.target.value);
                      // setFormatValuation(filteredValue);
                      const values = convertToNumber(filteredValue);

                      formik.setFieldValue("authorizedPreference", values);
                      formik.handleChange("authorizedPreference");
                    }}
                    value={numberWithCommas((formik.values.authorizedPreference || '')?.toString(), currencyType)}
                  />
                  {onHoverPreference && (
                    <ShowNumberInWords
                      value={formik.values.authorizedPreference!}
                      width={38}
                      currency={currencyType}
                    />
                  )}
                  {formik.touched?.authorizedPreference &&
                    formik.errors?.authorizedPreference && (
                      <Error text={formik.errors?.authorizedPreference} />
                    )}
                </VStack>
              </HStack>
              <HStack className="justify-between mt-10">
                <SecondaryCTAButton
                  event-name="Back Edit PPS and Valuation Modal"
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    onSecondaryAction();
                  }}
                >
                  Back
                </SecondaryCTAButton>
                <PrimaryCTAButton
                  event-name="Save Edit PPS and Valuation Modal"
                  buttonSize={ButtonSize.SMALL}
                  type="submit"
                >
                  Save & Continue
                </PrimaryCTAButton>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </div>
  );
}
