import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Dialog } from "@mui/material";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { Box, HStack } from "../../components/utils";
import { tableCellStyle } from "../../components/TableComponent";
import SearchInput from "../../components/shared/SearchInput";
import { setTableHeight } from "./utils";
import {
  InvestorDetails,
  InvestorState,
  ModelBuilder,
  OngoingInvestorDetails,
} from "../../types/Modeling";
import { getShareholderValue } from "../../constants/ShareholderConstants";
import {
  InvestorRightsComponent,
  PostAmountComponent,
  PostHoldingComponent,
  PreHoldingComponent,
  ShareholderNameModelRender,
} from "./AgComponent";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { CTADropdown } from "../../components/shared/Dropdown";
import DeleteModal from "../modeling/DeleteModal";
import CreateNewnvestorModal from "./CreateNewInvestorModal";
import AddNewInvestorsModal from "./AddNewInvestorModal";
import {
  ButtonSize,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import GenericTableHeader from "../../shared/TableHeader";
import { useTableStateManagement } from "../rounds/TableHook";
import { TableId } from "../../constants/TableIdConstants";
import { useAuthStore } from "../../store";
import CloudSetting from "../../components/shared/CloudIcon";

function NewInvestorAGGridTable({
  data,
  sharedPageData,
}: {
  data: InvestorDetails[];
  sharedPageData?: ModelBuilder;
}) {
  const gridApi = useRef<any>(null);

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();
  const { sendMessage: onNext, roundCreation: roundCreationData } =
    useRoundModelStore();

  const pathName = window.location.href.split("/");
  const sharedPage = useMemo(() => pathName[3] === "share", [pathName]);
  if (sharedPage && sharedPageData) {
    roundCreationData.currentTab = sharedPageData?.currentTab;
    roundCreationData.baseCaptableId = sharedPageData?.baseCaptableId;
    roundCreationData.companyId = sharedPageData?.companyId || "";
    roundCreationData.convertibles = sharedPageData?.convertibles;
    roundCreationData.errorMessage = sharedPageData?.errorMessage;
    roundCreationData.existingInvestorDetails =
      sharedPageData?.existingInvestorDetails;
    roundCreationData.highestVisitedTabLevel =
      sharedPageData.highestVisitedTabLevel;
    roundCreationData.isNavigating = sharedPageData?.isNavigating;
    roundCreationData.modelId = sharedPageData.modelId;
    roundCreationData.secondaries = sharedPageData.secondaries;
    roundCreationData.roundDetailsTab = sharedPageData.roundDetailsTab;
    roundCreationData.preCaptableHeaders = sharedPageData.preCaptableHeaders;
    roundCreationData.modelState = sharedPageData.modelState;
    roundCreationData.newInvestorDetails = sharedPageData.newInvestorDetails;
    roundCreationData.modelState = sharedPageData.modelState;
    roundCreationData.isTermsheet = sharedPageData.isTermsheet;
    roundCreationData.roundDetailsTab = sharedPageData.roundDetailsTab;
  }
  const companyId = useAuthStore().companyId ?? "";
  const [pageLoaded, setPageLoaded] = useState(false);

  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
    tableState,
  } = useTableStateManagement(
    gridApi,
    `${
      (roundCreationData.currentTab === 3 &&
        TableId.roundExistingInvestorTable) ||
      (roundCreationData.currentTab === 4 && TableId.roundNewInvestorTable)
    }${companyId}${roundCreationData.modelId}`,
    pageLoaded
  );

  const currencySymbol = getCurrencySymbol();
  const componentsRegistery = useMemo(
    () => ({
      investorName: memo(ShareholderNameModelRender),
      preHoldingPercentage: memo(PreHoldingComponent),
      postHoldingPercentage: memo(PostHoldingComponent),
      amountToInvest: memo(PostAmountComponent),
      optedInvestorRightType: memo(InvestorRightsComponent),
    }),
    []
  );
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const roundTemplate = useRoundModelStore();

  const columnDefs: ColDef[] = useMemo(
    () =>
      roundTemplate.roundCreation.currentTab === 6
        ? [
            {
              headerName: "Shareholder Name",
              field: "investorName",
              filter: "agMultiColumnFilter",
              getQuickFilterText: (params) => params.value.name,
              comparator(valueA: any, valueB: any, ..._: any[]) {
                return valueA.name > valueB.name ? 1 : -1;
              },
              filterParams: {
                maxNumConditions: 5,
                buttons: ["reset"],
                filters: [
                  {
                    title: "Name",
                    display: "subMenu",
                    filter: "agTextColumnFilter",
                    buttons: ["reset"],
                    filterValueGetter: (params: any) => params.value.name,
                    filterParams: {
                      buttons: ["reset"],
                      valueGetter: (params: any) =>
                        params.getValue("investorName").name,
                    },
                  },
                  {
                    title: "Type",
                    filter: "agSetColumnFilter",
                    display: "subMenu",
                    buttons: ["reset"],
                    filterParams: {
                      buttons: ["reset"],
                      keyCreator: (params: any) => {
                        const shareholderType = params.value.type;
                        return shareholderType;
                      },
                      valueFormatter: (params: any) => {
                        const shareholderType = getShareholderValue(
                          params.value.type
                        );
                        return shareholderType;
                      },
                    },
                  },
                ],
              },
              pinned: "left",
              cellRenderer: "investorName",
              initialWidth: 300,
              sortable: true,
              autoHeight: true,
              cellStyle: tableCellStyle,
              wrapText: true,
              suppressSizeToFit: true,
              menuTabs: ["filterMenuTab"],
            },
            {
              headerName: "Post holding %",
              field: "postHoldingPercentage",
              filter: "agNumberColumnFilter",
              cellRenderer: "postHoldingPercentage",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipPostHoldingPercentage",
            },
            {
              headerName: `Investment Amount (${currencySymbol})`,
              field: "amountToInvest",
              filter: "agNumberColumnFilter",
              cellStyle: tableCellStyle,
              sortable: true,
              cellRenderer: "amountToInvest",
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipAmountToInvest",
            },
            {
              headerName: "Investor Right",
              field: "optedInvestorRightType",
              filter: "agSetColumnFilter",
              cellRenderer: "optedInvestorRightType",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
            },
          ]
        : roundTemplate.roundCreation.currentTab === 4
        ? [
            {
              headerName: "Shareholder Name",
              field: "investorName",
              filter: "agMultiColumnFilter",
              getQuickFilterText: (params) => params.value.name,
              comparator(valueA: any, valueB: any, ..._: any[]) {
                return valueA.name > valueB.name ? 1 : -1;
              },
              filterParams: {
                maxNumConditions: 5,
                buttons: ["reset"],
                filters: [
                  {
                    title: "Name",
                    display: "subMenu",
                    filter: "agTextColumnFilter",
                    buttons: ["reset"],
                    filterValueGetter: (params: any) => params.value.name,
                    filterParams: {
                      buttons: ["reset"],
                      valueGetter: (params: any) =>
                        params.getValue("investorName").name,
                    },
                  },
                  {
                    title: "Type",
                    filter: "agSetColumnFilter",
                    display: "subMenu",
                    buttons: ["reset"],
                    filterParams: {
                      buttons: ["reset"],
                      keyCreator: (params: any) => {
                        const shareholderType = params.value.type;
                        return shareholderType;
                      },
                      valueFormatter: (params: any) => {
                        const shareholderType = getShareholderValue(
                          params.value.type
                        );
                        return shareholderType;
                      },
                    },
                  },
                ],
              },
              pinned: "left",
              cellRenderer: "investorName",
              initialWidth: 300,
              sortable: true,
              autoHeight: true,
              cellStyle: tableCellStyle,
              wrapText: true,
              suppressSizeToFit: true,
              menuTabs: ["filterMenuTab"],
            },

            {
              headerName: "Post holding %",
              field: "postHoldingPercentage",
              filter: "agNumberColumnFilter",
              cellRenderer: "postHoldingPercentage",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipPostHoldingPercentage",
            },
            {
              headerName: `Investment Amount (${currencySymbol})`,
              field: "amountToInvest",
              filter: "agNumberColumnFilter",
              cellStyle: tableCellStyle,
              sortable: true,
              cellRenderer: "amountToInvest",
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipAmountToInvest",
            },
            {
              headerName: "",
              field: "actions",
              pinned: "right",
              hide: false,
              width: 80,
              maxWidth: 80,
              filter: false,
              menuTabs: [],
              colId: "action-column",
              suppressNavigable: true,
              suppressColumnsToolPanel: true,
              cellStyle: { paddingTop: "10px", lineHeight: "20px" },
              resizable: false,
              sortable: false,
              cellRendererParams: ({ value }: { value: any }) => value.props,
              cellRenderer: CTADropdown,
            },
          ]
        : [
            {
              headerName: "Shareholder Name",
              field: "investorName",
              filter: "agMultiColumnFilter",
              getQuickFilterText: (params) => params.value.name,
              comparator(valueA: any, valueB: any, ..._: any[]) {
                return valueA.name > valueB.name ? 1 : -1;
              },
              filterParams: {
                maxNumConditions: 5,
                buttons: ["reset"],
                filters: [
                  {
                    title: "Name",
                    display: "subMenu",
                    filter: "agTextColumnFilter",
                    buttons: ["reset"],
                    filterValueGetter: (params: any) => params.value.name,
                    filterParams: {
                      buttons: ["reset"],
                      valueGetter: (params: any) =>
                        params.getValue("investorName").name,
                    },
                  },
                  {
                    title: "Type",
                    filter: "agSetColumnFilter",
                    display: "subMenu",
                    buttons: ["reset"],
                    filterParams: {
                      buttons: ["reset"],
                      keyCreator: (params: any) => {
                        const shareholderType = params.value.type;
                        return shareholderType;
                      },
                      valueFormatter: (params: any) => {
                        const shareholderType = getShareholderValue(
                          params.value.type
                        );
                        return shareholderType;
                      },
                    },
                  },
                ],
              },
              pinned: "left",
              cellRenderer: "investorName",
              initialWidth: 300,
              sortable: true,
              autoHeight: true,
              cellStyle: tableCellStyle,
              wrapText: true,
              suppressSizeToFit: true,
              menuTabs: ["filterMenuTab"],
            },
            {
              headerName: "Pre holding %",
              field: "preHoldingPercentage",
              filter: "agNumberColumnFilter",
              cellRenderer: "preHoldingPercentage",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipPreHoldingPercentage",
            },
            {
              headerName: "Post holding %",
              field: "postHoldingPercentage",
              filter: "agNumberColumnFilter",
              cellRenderer: "postHoldingPercentage",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipPostHoldingPercentage",
            },
            {
              headerName: `Investment Amount (${currencySymbol})`,
              field: "amountToInvest",
              filter: "agNumberColumnFilter",
              cellStyle: tableCellStyle,
              sortable: true,
              cellRenderer: "amountToInvest",
              menuTabs: ["filterMenuTab"],
              tooltipField: "tooltipAmountToInvest",
            },
            {
              headerName: "Investor Right",
              field: "optedInvestorRightType",
              filter: "agSetColumnFilter",
              cellRenderer: "optedInvestorRightType",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
            },
            {
              headerName: "",
              field: "actions",
              pinned: "right",
              hide: false,
              width: 80,
              maxWidth: 80,
              filter: false,
              menuTabs: [],
              colId: "action-column",
              suppressNavigable: true,
              suppressColumnsToolPanel: true,
              cellStyle: { paddingTop: "10px", lineHeight: "20px" },
              resizable: false,
              sortable: false,
              cellRendererParams: ({ value }: { value: any }) => value.props,
              cellRenderer: CTADropdown,
            },
          ],
    []
  );

  const currencyType = getCurrencyType();

  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: string;
    data?: any;
  }>({
    open: false,
  });

  function handleModifyAction(action: string, data: any) {
    if (action === "Delete") {
      setDialog({
        open: true,
        mode: "Delete",
        data,
      });
    } else if (action === "Edit") {
      setDialog({
        open: true,
        mode: "Edit",
        data,
      });
    }
  }

  const rowData = useMemo(() => {
    setPageLoaded(true);
    return data?.map((template) => ({
      investorId: template.investorId,
      investorName: {
        name: template.investorName,
        type: template.investorType,
      },
      investorType: template.investorType,
      optedInvestorRightType: template.optedInvestorRightType,
      amountToInvest: template.hasBlendedSecondaries
        ? template.blendedSecondaries?.primaryInvestmentAmount
        : template.amountToInvest,
      tooltipAmountToInvest: template.hasBlendedSecondaries
        ? template.blendedSecondaries?.primaryInvestmentAmount.toLocaleString(
            currencyType
          )
        : template.amountToInvest.toLocaleString(currencyType),
      postHoldingPercentage: template.hasBlendedSecondaries
        ? template.blendedSecondaries?.primaryInvestmentHoldingPercentage
        : template.postHoldingPercentage,
      tooltipPreHoldingPercentage: (
        template.preHoldingPercentage ?? 0
      ).toLocaleString(currencyType),
      preHoldingPercentage: template.preHoldingPercentage ?? 0,
      tooltipPostHoldingPercentage: template.hasBlendedSecondaries
        ? template.blendedSecondaries?.primaryInvestmentHoldingPercentage
        : template.postHoldingPercentage,
      hasBlended: template.hasBlendedSecondaries,
      blendedSecondaryAmount:
        template.blendedSecondaries?.secondaryInvestmentAmount,
      blendedSecondaryPercentage:
        template.blendedSecondaries?.secondaryInvestmentHoldingPercentage,
      currencySymbol,
      currencyType,
      actions: (
        <CTADropdown
          actions={[
            {
              name: "Edit",
              disabled: false,
            },
            {
              name: "Delete",
              disabled: false,
            },
          ]}
          onAction={(action) => {
            handleModifyAction(action.name, template);
          }}
        />
      ),
    }));
  }, [data]);
  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const onAgGridFilterChanged = (grid: any) => {
    setPinnedBottomRowData(grid);
    uploadFilter();
  };

  useEffect(() => {
    const filterModel = gridApi.current?.api.getFilterModel();

    const filteredData = gridApi.current?.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);

    setFilteredRowData(filteredData);
    gridApi.current?.api.setFilterModel(tableState?.filterState);
  }, [gridApi, tableState?.filterState, tableState?.columnState, data]);

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );

    const totalAmountToInvest = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator +
        (data.hasBlendedSecondaries
          ? data.blendedSecondaries?.primaryInvestmentAmount ?? 0
          : data.amountToInvest),
      0
    );
    // const totalPostHoldingPercentage = filteredData?.reduce(
    //   (accumulator: any, data: any) =>
    //     accumulator +
    //     (data.hasBlendedSecondaries
    //       ? data.blendedSecondaries?.finalInvestmentHoldingPercentage
    //       : data.postHoldingPercentage),
    //   0
    // );
    const secondaryTotalAmountToInvest = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + (data.blendedSecondaries?.secondaryInvestmentAmount ?? 0),
      0
    );
    // const secondaryTotalPostHoldingPercentage = filteredData?.reduce(
    //   (accumulator: any, data: any) =>
    //     accumulator +
    //     data.blendedSecondaries?.secondaryInvestmentHoldingPercentage,
    //   0
    // );
    const blended = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator || data.hasBlendedSecondaries,
      0
    );

    api.setPinnedBottomRowData([
      {
        investorId: "total",
        investorName: {
          name: "Total",
          type: "",
        },
        investorType: "",
        optedInvestorRightType: "",
        blendedSecondaryAmount: secondaryTotalAmountToInvest,
        blendedSecondaryPercentage: undefined,
        amountToInvest: totalAmountToInvest,
        tooltipAmountToInvest: totalAmountToInvest.toLocaleString(currencyType),
        postHoldingPercentage: undefined,
        tooltipPostHoldingPercentage: undefined,
        hasBlended: blended,
        actions: <div></div>,
        currencySymbol,
        currencyType,
      },
    ]);
  }

  const handleSubmit = async (id: string) => {
    const ongoingExistingInvestorDetails: OngoingInvestorDetails = {
      investorId: id,
      investorName: "",
      investorType: "",
      amountToInvest: 0,
      postHoldingPercentage: 0,
      state: InvestorState.ToBeDeleted,
      unLockedInputFeild: {
        hasToCalculateAmt: false,
        value: 0,
      },
      hasBlendedSecondaries: false,
    };
    const newList: ModelBuilder =
      roundCreationData.currentTab === 3
        ? {
            ...roundCreationData,

            existingInvestorDetails: {
              listOfInvestor:
                roundCreationData.existingInvestorDetails?.listOfInvestor ?? [],
              investorDetails:
                roundCreationData.existingInvestorDetails?.investorDetails ??
                [],
              ongoingExistingInvestorDetails,
            },
          }
        : {
            ...roundCreationData,
            newInvestorDetails: {
              investorDetails:
                roundCreationData.newInvestorDetails?.investorDetails ?? [],
              ongoingExistingInvestorDetails,
            },
          };
    onNext(newList);
    setDialog({ open: false });
  };

  return (
    <div className="bg-white rounded-md border-borderColor shadow-box">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Add" && roundCreationData.currentTab === 4 && (
          <CreateNewnvestorModal
            data={dialog.data}
            onBack={() => {
              setDialog({
                open: false,
              });
            }}
            onNext={onNext}
            actionMode="New"
          />
        )}
        {dialog.mode === "Add" && roundCreationData.currentTab === 3 && (
          <AddNewInvestorsModal
            onBack={() => {
              setDialog({ open: false });
            }}
            onNext={onNext}
          />
        )}
        {dialog.mode === "Edit" && roundCreationData.currentTab === 3 && (
          <AddNewInvestorsModal
            actionMode="Edit"
            data={dialog.data}
            onBack={() => {
              setDialog({ open: false });
            }}
            onNext={onNext}
          />
        )}
        {dialog.mode === "Edit" && roundCreationData.currentTab === 4 && (
          <CreateNewnvestorModal
            data={dialog.data}
            onBack={() => {
              setDialog({
                open: false,
              });
            }}
            onNext={onNext}
            actionMode="Edit"
          />
        )}
        {dialog.mode === "Delete" && (
          <DeleteModal
            onPrimaryAction={() => {
              handleSubmit(dialog.data?.investorId || "");
            }}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the entry? Data will be permanently removed. This action cannot be undone."
            }
          />
        )}
      </Dialog>
      <HStack className="flex-col items-start justify-start py-2 lg:flex-row lg:justify-between lg:items-center ">
        {roundCreationData.currentTab === 6 ? (
          <div></div>
        ) : (
          <GenericTableHeader
            heading={
              roundCreationData.currentTab === 3
                ? "Existing Investor"
                : roundCreationData.currentTab === 4
                ? "New Investor"
                : ""
            }
            subHeading={
              roundCreationData.currentTab === 3 ||
              roundCreationData.currentTab === 4
                ? "Investors"
                : ""
            }
            count={rowData.length}
          />
        )}

        <HStack className="items-center justify-between w-full gap-4 px-3 lg:w-auto lg:justify-end">
          <CloudSetting
            tableId={`${
              (roundCreationData.currentTab === 3 &&
                TableId.roundExistingInvestorTable) ||
              (roundCreationData.currentTab === 4 &&
                TableId.roundNewInvestorTable)
            }${companyId}${roundCreationData.modelId}`}
            isLoading={isSaving}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
          />
          <SearchInput
            placeholder={`Search`}
            className="h-8"
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          {roundCreationData.currentTab === 6 ? (
            <div></div>
          ) : (
            <SecondaryCTAButton
              className="h-9"
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                setDialog({ open: true, mode: "Add" });
              }}
              disabled={
                roundCreationData.onGoingRoundHeaders?.leftToRaise !==
                  undefined &&
                roundCreationData.onGoingRoundHeaders.leftToRaise <= 0
              }
            >
              + Add
            </SecondaryCTAButton>
          )}
        </HStack>
      </HStack>

      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(displayedRowCount),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            suppressScrollOnNewData={true}
            alwaysShowHorizontalScroll={false}
            alwaysShowVerticalScroll={false}
            components={componentsRegistery}
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            onColumnMoved={uploadColumn}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default NewInvestorAGGridTable;
