import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { Icon } from "@iconify/react";

import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import { ShareholderSecurityModel } from "../../types/Shareholder";

import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  AmountInvestedRender,
  CurrentValueRender,
  NumberOfSharesRender,
  PPSRender,
  RoundsRender,
  SecurityClassRender,
  SecurityTypeRender,
} from "./ShareholderAgSecuritiesTableComponent";
import {
  beneficiaryAllowedSecurities,
  convertibleSecurities,
  getTypeOfSecurity,
  redeemableSecurities,
} from "../../constants/SecurityConstantContent";
import {
  regenerateCertificate,
  convertDematShare,
} from "../../api/shareholders";
import { queryClient } from "../../queries";
import { downloadS3File } from "../../utils/DownloadFile";
import {
  useConvertDematShare,
  useUploadCustomShareCertificate,
} from "../../queries/shareholders";

import AlertDialog from "../../components/shared/AlertDialog";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";

import { UpdateCertificateFDB } from "../../modals/UpdateShareCertificateFDB";

import {
  AddBeneficiaryDetails,
  transformRangesArraytoString,
} from "../../modals/AddBeneficiaryDetails";
import { AddConversionDetails } from "../../modals/AddConversionDetails";
import AddPartlyPaidDetails from "../../modals/AddPartlyPaidDetails";
import { AddRedemptionDetails } from "../../modals/AddRedemptionDetails";
import { globalFilter, sort } from "../../utils/arrays";

import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { Box, HStack, VStack } from "../../components/utils";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAgGridTableState } from "../../store/agGridTableStore";
import { tableCellStyle } from "../../components/TableComponent";
import SearchInput from "../../components/shared/SearchInput";
import { RoundsToolTip } from "../dashboardPage/customTooltip";
import { SplitShareCertificateRanges } from "../../modals/SplitShareCertificateRanges";
import { ShareNumbersRender } from "./ShareholderAGTransactionDetailsComponent";
import { useTableStateManagement } from "../rounds/TableHook";
import { TableId } from "../../constants/TableIdConstants";
import CloudSetting from "../../components/shared/CloudIcon";

function AgGridShareholderSecuritiesTable({
  shareholderSecurities,
  shareholderId,
}: {
  shareholderSecurities: ShareholderSecurityModel[];
  currencyType: string;
  shareholderId: string;
}) {
  const [certificateId, setCertificateId] = useState("");
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: ShareholderSecurityModel;
    index?: number;
    mode?:
      | "Edit"
      | "Delete"
      | "Conversion"
      | "Redemption"
      | "Settle Partly Paid Shares"
      | "Add Beneficiaries"
      | "Upload Custom Share Certificate"
      | "View Share Certificate"
      | "Download Share Certificate"
      | "Re-Generate Share Certificate"
      | "Override FDB Shares"
      | "Split Share Numbers";
  }>({
    open: false,
  });
  const { mutate: convertDematShare } = useConvertDematShare();
  const { mutate: uploadCustomShareCertificate } =
    useUploadCustomShareCertificate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof ShareholderSecurityModel | "" | undefined;
    ascending: boolean;
  }>({ field: "securityClass", ascending: true });
  const canEditSecurity = useCanUserAccess("update", "instrumentclass");
  const gridApi = useRef<any>(null);

  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );

  const path = window.location.pathname.split("/") || [];
  const shareHolderId = path[path.length - 1] || "";

  const [pageLoaded, setPageLoaded] = useState(false);

  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
    tableState,
  } = useTableStateManagement(
    gridApi,
    `${TableId.shareCertificateTable}${companyId}${shareHolderId}`,
    pageLoaded
  );

  const agTableStore = useAgGridTableState();

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.shareholderSecurityColumnModel);
      setFilterSetting(data.data?.shareholderSecurityFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        shareholderSecurityColumnModel: columnState,
        shareholderSecurityFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      securityClass: memo(SecurityClassRender),
      securityType: memo(SecurityTypeRender),
      numberOfShares: memo(NumberOfSharesRender),
      rounds: memo(RoundsRender),
      pps: memo(PPSRender),
      amountInvested: memo(AmountInvestedRender),
      currentValue: memo(CurrentValueRender),
      shareNumbers: memo(ShareNumbersRender),
    }),
    []
  );

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Security Class",
        filter: "agSetColumnFilter",
        field: "securityClass",
        pinned: "left",
        cellRenderer: "securityClass",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurityClass",
      },
      {
        headerName: "Rounds",
        field: "rounds",
        cellRenderer: "rounds",
        initialWidth: 250,
        cellStyle: {
          "padding-top": "5px",
          "line-height": "20px",
        },
        sortable: false,
        tooltipField: "tooltipSecurityClass",
        tooltipComponent: RoundsToolTip,
        menuTabs: [],
      },
      {
        headerName: "Security Type",
        field: "securityType",
        filter: "agSetColumnFilter",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "No. of Securities",
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberOfShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNumberOfShares",
      },
      {
        headerName: `PPS (${currencySymbol})`,
        field: "pps",
        filter: "agNumberColumnFilter",
        cellRenderer: "pps",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPricePerShare",
      },
      {
        headerName: `Amount Invested (${currencySymbol})`,
        field: "amountInvested",
        filter: "agNumberColumnFilter",
        cellRenderer: "amountInvested",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmount",
      },
      {
        headerName: `Current Value (${currencySymbol})`,
        field: "currentValue",
        filter: "agNumberColumnFilter",
        cellRenderer: "currentValue",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipCurrentValue",
      },
      {
        headerName: "Share Numbers",
        field: "shareNumbers",
        cellRenderer: "shareNumbers",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: [],
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "10px", "line-height": "20px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );
  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();

  shareholderSecurities = useMemo(() => {
    if (!shareholderSecurities) return [];
    try {
      const filterResult = globalFilter(
        shareholderSecurities,
        globalFilterText,
        [
          "securityClass",
          "securityType",
          "round",
          "numberOfShares",
          "pricePerShare",
          "amountInvested",
          "currentValue",
        ]
      );
      const sortResult = sort(
        filterResult,
        sortField?.field,
        sortField?.ascending
      );
      return sortResult;
    } catch (e) {
      return shareholderSecurities;
    }
  }, [shareholderSecurities, globalFilterText, sortField]);

  function handleModifyAction(action: string, data: ShareholderSecurityModel) {
    if (action === "Conversion") {
      setDialog({
        open: true,
        mode: "Conversion",
        data,
      });
    } else if (action === "Re-Generate Share Certificate") {
      regenerateCertificate(data.certificateId)
        .then((_) => {
          toast("Share Certificate Regenerated Successfully", {
            type: "success",
            autoClose: 2000,
          });
          queryClient.invalidateQueries("getIndividualShareholderOverview");
          queryClient.refetchQueries("getIndividualShareholderOverview");
        })
        .catch((err) => {});
    } else if (action === "Upload Custom Share Certificate") {
      fileRef.current.click();
    } else if (action === "View Share Certificate") {
      setDialog({
        open: true,
        mode: "View Share Certificate",
        data,
      });
    } else if (action === "Download Share Certificate") {
      downloadS3File(data.certificateUrl, data.certificateName);
    } else if (action === "Convert to Physical Share") {
      convertDematShare(
        {
          certificateId: data.certificateId,
          demat: false,
        },
        {
          onSuccess: async () => {
            toast("Share Converted Successfully!", {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: (err: any) => {
            toast(err.response.data.errorMessage, {
              type: "error",
              autoClose: 2000,
            });
          },
        }
      );
    } else if (action === "Convert to Demat") {
      convertDematShare(
        {
          certificateId: data.certificateId,
          demat: true,
        },
        {
          onSuccess: async (data) => {
            toast("Share Converted Successfully!", {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: (err: any) => {
            toast(err.response.data.errorMessage, {
              type: "error",
              autoClose: 2000,
            });
          },
        }
      );
    } else if (action === "Redemption") {
      setDialog({
        open: true,
        mode: "Redemption",
        data,
      });
    } else if (action === "Override FDB Shares") {
      setDialog({
        open: true,
        mode: "Override FDB Shares",
        data,
      });
    } else if (action === "Add Beneficiaries") {
      setDialog({
        open: true,
        mode: "Add Beneficiaries",
        data,
      });
    } else if (action === "Settle Partly Paid Shares") {
      setDialog({
        open: true,
        mode: "Settle Partly Paid Shares",
        data,
      });
    } else if (action === "Split Share Numbers") {
      setDialog({
        open: true,
        mode: "Split Share Numbers",
        data,
      });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = ["pdf"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      const formData = new FormData();
      formData.append("file", file);
      uploadCustomShareCertificate(
        {
          formData,
          certificateId,
        },
        {
          onSuccess: async () => {
            toast("Share Certificate Uploaded Successfully!", {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: (err: any) => {
            toast(err.response.data.errorMessage, {
              type: "error",
              autoClose: 2000,
            });
          },
        }
      );
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
    fileRef.current.value = "";
  }

  const rowData = useMemo(() => {
    setPageLoaded(true);
    return shareholderSecurities?.map((template) => ({
      securityClass: template.securityClass,
      tooltipSecurityClass: template.securityClass,
      securityType: template.securityType,
      rounds: [template.roundDetails],
      demat: template.demat,
      numberOfShares: template.numberOfShares,
      tooltipNumberOfShares:
        template.numberOfShares.toLocaleString(currencyType),
      pps: template.pricePerShare,
      tooltipPricePerShare: template.pricePerShare.toLocaleString(currencyType),
      amountInvested: template.amountInvested,
      tooltipAmount: template.amountInvested.toLocaleString(currencyType),
      currentValue: template.currentValue,
      tooltipCurrentValue: template.currentValue.toLocaleString(currencyType),
      currencyType,
      currencySymbol,
      shareNumber: [
        transformRangesArraytoString(template.ranges, template.securityType),
      ],
      actions: (
        <CTADropdown
          actions={[
            {
              name: "Conversion",
              disabled: !(
                convertibleSecurities.includes(template.securityType) ?? false
              ),
            },
            {
              name: "Redemption",
              disabled: !(
                redeemableSecurities.includes(template.securityType) ?? false
              ),
            },
            {
              name: template.demat
                ? "Convert to Physical Share"
                : "Convert to Demat",
              disabled: false,
            },
            {
              name: "Re-Generate Share Certificate",
              disabled: false,
            },
            {
              name: "Upload Custom Share Certificate",
              disabled: false,
            },
            {
              name: "View Share Certificate",
              disabled: false,
            },
            {
              name: "Download Share Certificate",
              disabled: false,
            },
            {
              name: "Override FDB Shares",
              disabled: false,
            },
            {
              name: "Add Beneficiaries",
              disabled: !(
                beneficiaryAllowedSecurities.includes(template.securityType) ??
                false
              ),
            },
            {
              name: "Settle Partly Paid Shares",
              disabled: false,
            },
          ]}
          onAction={(action) => {
            setCertificateId(template.certificateId);

            handleModifyAction(action.name, template);
          }}
        />
      ),
    }));
  }, [shareholderSecurities]);

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const selectedRowIndex = cellParams.node.rowIndex || 0;
      const template = rowData[selectedRowIndex];
      if (cellParams.column.getColId() === "transactionName") {
        //dirty comment
        // onClickTransactionName(template.transactionType, template.id);
      }
    }
  };

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
    uploadFilter();
  };

  useEffect(() => {
    const filterModel = gridApi.current?.api.getFilterModel();

    const filteredData = gridApi.current?.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);

    setFilteredRowData(filteredData);
    gridApi.current?.api.setFilterModel(tableState?.filterState);
  }, [
    gridApi,
    tableState?.filterState,
    tableState?.columnState,
    shareHolderId,
  ]);

  return (
    <>
      <HStack className="justify-between py-2 items-center shadow-box bg-white rounded-md">
        <GenericTableHeader
          heading={"Share Certificates"}
          subHeading={"certificates"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="justify-end py-2 items-center gap-4 mr-4">
          {/* <QuickSearch
            placeholder={`Search`}
            onChange={(e) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          /> */}
          <CloudSetting
            tableId={`${TableId.shareCertificateTable}${companyId}${shareHolderId}`}
            isLoading={isSaving}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
          />
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>

      <HStack className="justify-between w-full">
        <div className="flex-col justify-between pt-4 sm:flex lg:flex-row">
          <input
            ref={fileRef}
            onChange={handleChange}
            multiple={false}
            type="file"
            accept=".pdf"
            hidden
          />
          <Dialog open={dialog.open} maxWidth="md">
            {dialog.mode === "Delete" ? (
              <AlertDialog
                onClose={() => setDialog({ open: false })}
                open={dialog.open}
                message={dialog.message}
                onPrimaryAction={() => {}}
                onSecondaryAction={() => setDialog({ open: false })}
              />
            ) : dialog.mode === "Conversion" && dialog.data ? (
              <AddConversionDetails
                shareholderSecurity={dialog.data}
                onPrimaryAction={() => {
                  setDialog({ open: false });
                }}
                onSecondaryAction={() => {
                  setDialog({ open: false });
                }}
                shareholderId={shareholderId}
                certificateId={dialog.data.certificateId}
              />
            ) : dialog.mode === "Redemption" && dialog.data ? (
              <AddRedemptionDetails
                shareholderSecurity={dialog.data}
                onPrimaryAction={() => {
                  setDialog({ open: false });
                }}
                onSecondaryAction={() => {
                  setDialog({ open: false });
                }}
                shareholderId={shareholderId}
                certificateId={dialog.data.certificateId}
              />
            ) : dialog.mode === "View Share Certificate" && dialog.data ? (
              <Dialog
                open={dialog.open}
                onClose={() => setDialog({ open: false })}
                maxWidth="lg"
              >
                <LazyPdfDocument
                  url={dialog?.data?.certificateUrl}
                  onClose={() => {
                    setDialog({ open: false });
                  }}
                />
              </Dialog>
            ) : dialog.mode === "Settle Partly Paid Shares" && dialog.data ? (
              <AddPartlyPaidDetails
                shareholderSecurity={dialog.data}
                onPrimaryAction={() => {
                  setDialog({ open: false });
                }}
                onSecondaryAction={() => {
                  setDialog({ open: false });
                }}
              />
            ) : dialog.mode === "Add Beneficiaries" && dialog.data ? (
              <AddBeneficiaryDetails
                shareholderSecurity={dialog.data}
                shareholderId={shareholderId}
                onPrimaryAction={() => {
                  setDialog({ open: false });
                }}
                onSecondaryAction={() => {
                  setDialog({ open: false });
                }}
              />
            ) : dialog.mode === "Override FDB Shares" && dialog.data ? (
              <UpdateCertificateFDB
                certificateId={dialog.data.certificateId}
                fdbShares={dialog.data.fdbShares}
                remarks={dialog.data.remarks}
                onPrimaryAction={() => {
                  setDialog({ open: false });
                }}
                onSecondaryAction={() => {
                  setDialog({ open: false });
                }}
              />
            ) : dialog.mode === "Split Share Numbers" && dialog.data ? (
              <SplitShareCertificateRanges
                certificateId={dialog.data.certificateId}
                existingRanges={dialog.data.ranges}
                onPrimaryAction={() => {
                  setDialog({ open: false });
                }}
                onSecondaryAction={() => {
                  setDialog({ open: false });
                }}
              />
            ) : (
              <div></div>
            )}
          </Dialog>
        </div>
        <Box
          style={{
            height: (displayedRowCount + 7.5) * 60,
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            enableCharts={true}
            enableRangeSelection={true}
            animateRows={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            onColumnMoved={uploadColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed"}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            gridOptions={gridOptions}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}

export default AgGridShareholderSecuritiesTable;
