import _ from "lodash";
import { tableEntryStyle } from "../../components/TableComponent";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { getSecurityColor } from "../../utils/historicUtilities";
import { limitString } from "../../utils/string";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { formatDisplayDate } from "../../utils/date";

export function EmployeeNameRender(data: any) {
  const shareholderStore = useShareholderInstrumentStore();
  const shareholders = shareholderStore.shareholders;
  const name =
    data.data.companyInvestorId && data.data.isExistingShareholder
      ? shareholders.find(
          (shareholder) => shareholder.id === data.data.companyInvestorId
        )?.name || ""
      : data.data.employeeName;
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{limitString(name, 24)}</p>
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function AmountRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.amount,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function ExercisePriceRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.exercisePrice,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function SecurityRender(data: any) {
  const instrumentStore = useShareholderInstrumentStore();
  const instruments = instrumentStore.instrumentClasses;
  const name =
    instruments.find(
      (instrument) => instrument.id === data.data.instrumentClassId
    )?.name || "";
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{name}</p>
    </div>
  );
}

export function DateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      {data.data.date ? (
        <p className={tableEntryStyle}>{formatDisplayDate(data.data.date)}</p>
      ) : (
        <p className={tableEntryStyle}>-</p>
      )}
    </div>
  );
}
