import { QueryFunctionContext } from "react-query";
import {
  AddNewShareHolderModel,
  QuickRoundSubmitResponse,
} from "../types/quickRound";
import {
  DematBody,
  IndividualShareholderModel,
  SecurityConversionModel,
  SecurityRedemptionModel,
  ShareholderDataModel,
  ShareholderGroup,
  ShareholderProfile,
  ShareholderRangeModel,
  BeneficialInvestor,
  BeneficiaryCertificatesInput,
  BeneficiaryUpdateModel,
  UpdateTransactionInput,
  PartlyPaidDetails,
  SchedulePay,
} from "../types/Shareholder";
import api from "./capTableApi";
import {
  EsopPlanDetail,
  EsopPlanDetailsModel,
} from "../pages/shareholders/EsopPlanDetailsAgTable";

export async function getShareholderOverview(): Promise<
  ShareholderDataModel[]
> {
  return api
    .get("v1/company/companyInvestor/overview")
    .then((res) => res.data.data);
}

export async function getConversionDetails(
  context: QueryFunctionContext
): Promise<SecurityConversionModel> {
  const securityClassId = context.queryKey[1];
  const shareholderId = context.queryKey[2];
  const certificateId = context.queryKey[3];
  return api
    .get("v1/company/companyInvestor/conversion", {
      params: { securityClassId, shareholderId, certificateId },
    })
    .then((res) => res.data.data);
}

export async function getIndividualShareholderOverview(
  context: QueryFunctionContext
): Promise<IndividualShareholderModel> {
  const cInvestorId = context.queryKey[1];
  return api
    .get("v1/company/companyInvestor/individual", {
      params: { cInvestorId },
    })
    .then((res) => res.data.data);
}

export async function getShareholderRanges(): Promise<ShareholderRangeModel[]> {
  return api
    .get("v1/company/companyInvestor/shareRanges")
    .then((res) => res.data.data);
}

export async function getRedemptionDetails(
  context: QueryFunctionContext
): Promise<SecurityRedemptionModel> {
  const securityClassId = context.queryKey[1];
  const shareholderId = context.queryKey[2];
  const certificateId = context.queryKey[3];
  return api
    .get("v1/company/companyInvestor/redemption", {
      params: { securityClassId, shareholderId, certificateId },
    })
    .then((res) => res.data.data);
}

export async function getShareholderGroups(): Promise<ShareholderGroup[]> {
  return api
    .get("v1/company/companyInvestor/groups")
    .then((res) => res.data.data);
}

export async function updateConversionDetails(
  newSecurityDetail: any
): Promise<any> {
  return api
    .post(`v1/company/companyInvestor/conversion`, newSecurityDetail)
    .then((res) => res.data.data);
}

export async function addNewShareHolder(
  newShareHolder: AddNewShareHolderModel | ShareholderProfile
): Promise<void> {
  return api
    .post(`v1/company/companyInvestor/`, [newShareHolder])
    .then((res) => res.data);
}

export async function updateRedemptionDetails(
  newSecurityDetail: any
): Promise<QuickRoundSubmitResponse> {
  return api
    .post(`v1/company/companyInvestor/redemption`, newSecurityDetail)
    .then((res) => res.data.data);
}

export async function editShareholder(
  shareholder: ShareholderProfile
): Promise<void> {
  return api
    .put(`v1/company/companyInvestor`, shareholder)
    .then((res) => res.data);
}

export async function convertDematShare(
  sharedetails: DematBody
): Promise<void> {
  return api
    .put(`v1/company/shareCertificate/updateDemat`, sharedetails)
    .then((res) => res.data);
}

export async function regenerateCertificate(
  certificateId: string
): Promise<any> {
  return api
    .get(`v1/company/companyInvestor/generateShareCertificate`, {
      params: { certificateId },
    })
    .then((res) => res.data);
}

export async function uploadCustomShareCertificate({
  formData,
  certificateId,
}: {
  formData: FormData;
  certificateId: string;
}): Promise<void> {
  return api
    .post(
      `v1/company/companyInvestor/uploadCustomShareCertificate?certificateId=${certificateId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => res.data.data);
}

export async function uploadCompanyExcel({
  formData,
}: {
  formData: FormData;
}): Promise<void> {
  return api
    .post(`v1/company/uploadCompanyExcel`, formData)
    .then((res) => res.data.data);
}

export async function uploadMultipleCompanyFile({
  formData,
}: {
  formData: FormData;
}): Promise<void> {
  return api
    .post(`v1/company/uploadCompanyFile`, formData)
    .then((res) => res.data.data);
}

//used for uploading customtemplate ui is not ready yet
export async function uploadCustomTemplate({
  formData,
  companyId,
}: {
  formData: FormData;
  companyId: string;
}): Promise<{ message: string; data: any }> {
  return api
    .post(
      `v1/company/companyInvestor/uploadTemplate?companyId=${companyId}&type=ShareCertificate`,
      formData
    )
    .then((res) => res.data);
}

//used for uploading customtemplate ui is not ready yet
export async function uploadDefaultTemplate({
  formData,
}: {
  formData: FormData;
}): Promise<{ message: string; data: any }> {
  return api
    .post(
      `v1/company/companyInvestor/uploadDefaultTemplate?type=ShareCertificate`,
      formData
    )
    .then((res) => res.data);
}

export async function updateCertificateFdb({
  certificateId,
  fdbShares,
  remarks,
}: {
  certificateId: string;
  fdbShares: number | null;
  remarks: string;
}): Promise<{ message: string; data: any }> {
  return api
    .put(`v1/company/companyInvestor/updateCertificateFdb`, {
      certificateId,
      fdbShares,
      remarks,
    })
    .then((res) => res.data);
}

export async function updateBeneficiaryInvestors(
  beneficialInvestors: BeneficialInvestor[]
) {
  return api
    .post(`v1/company/companyInvestor/beneficialInvestors`, beneficialInvestors)
    .then((res) => res.data);
}

export async function getAllBeneficialInvestors(
  context: QueryFunctionContext
): Promise<BeneficialInvestor[]> {
  const cInvestorId = context.queryKey[1];
  return api
    .get(
      `v1/company/companyInvestor/beneficialInvestors?companyInvestorId=${cInvestorId}`
    )
    .then((res) => res.data.data);
}

export async function updateBeneficiayCertificates(
  beneficiaryInput: BeneficiaryCertificatesInput
) {
  return api
    .put(`v1/company/companyInvestor/beneficiaryCertificates`, beneficiaryInput)
    .then((res) => res.data);
}

export async function getAllBeneficialCertificates(
  context: QueryFunctionContext
): Promise<BeneficiaryUpdateModel[]> {
  const certificateId = context.queryKey[1];
  return api
    .get(
      `v1/company/companyInvestor/beneficiaryCertificates?certificateId=${certificateId}`
    )
    .then((res) => res.data.data);
}

export async function validateBeneficiaryCertificates(
  beneficiaryInput: BeneficiaryCertificatesInput
) {
  return api
    .post(
      `v1/company/companyInvestor/validateBeneficiaryCertificates`,
      beneficiaryInput
    )
    .then((res) => res.data);
}

export async function updateTransaction(
  updateTransactionInput: UpdateTransactionInput
) {
  return api
    .post(`v1/company/transaction/updateTransaction`, updateTransactionInput)
    .then((res) => res.data);
}

export async function splitShareRanges({
  certificateId,
  ranges,
}: {
  certificateId: string;
  ranges: number[][];
}): Promise<{ message: string; data: string }> {
  return api
    .put(`v1/company/companyInvestor/splitShareRanges`, {
      certificateId,
      ranges,
    })
    .then((res) => res.data);
}

export async function getPartlyPaidDetails(
  context: QueryFunctionContext
): Promise<PartlyPaidDetails> {
  const certificateId = context.queryKey[1];
  return api
    .get(
      `v1/company/companyInvestor/partlyPaidSchedule?certificateId=${certificateId}`
    )
    .then((res) => res.data.data);
}

export async function updatePartlyPaidDetails({
  certificateId,
  scheduleDetails,
}: {
  certificateId: string;
  scheduleDetails: SchedulePay[];
}): Promise<{ message: string; data: string }> {
  return api
    .put(`v1/company/companyInvestor/partlyPaidSchedule`, {
      certificateId,
      scheduleDetails,
    })
    .then((res) => res.data);
}

export async function addEsopDetails(
  newEsopDetail: EsopPlanDetail
): Promise<any> {
  return api
    .post(`/v1/company/companyInvestor/esopPlanDetails`, {
      esopPlanDetails: newEsopDetail.planDetails,
      cInvestorId: newEsopDetail.cInvestorId,
    })
    .then((res) => res.data.data);
}

export async function updateEsopDetails(
  newEsopDetail: EsopPlanDetailsModel
): Promise<any> {
  return api
    .put(`/v1/company/companyInvestor/esopPlanDetails`, {
      esopPlanDetails: newEsopDetail,
    })
    .then((res) => res.data);
}

export async function deleteEsopDetails(id: string): Promise<any> {
  return api
    .delete(`/v1/company/companyInvestor/esopPlanDetails`, {
      params: { esopPlanDetailsId: id },
    })
    .then((res) => res.data.data);
}
