import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GetDataPath,
  GridApi,
  SideBarDef,
} from "ag-grid-community";

import { useNavigate } from "react-router";

import { AgGridReact } from "ag-grid-react";

import { Box } from "@mui/material";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../../utils/currencyFormatter";
import { tableCellStyle } from "../../../../components/TableComponent";
import SearchInput from "../../../../components/shared/SearchInput";
import { useGetCompanyName, HStack } from "../../../../components/utils";
import GenericTableHeader from "../../../../shared/TableHeader";
import { useAuthStore } from "../../../../store";
import { useAgGridTableState } from "../../../../store/agGridTableStore";
import { downloadExcel } from "../../../../utils/DownloadFile";
import { ExportImport } from "../../../Utility/GrantsTable";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../../../dashboardPage/AgGridCacheQuery";
import { agConfigDashboardSideBar } from "../../../dashboardPage/AgGridConfig";
import { financeCustomToolTip } from "../../../dashboardPage/customTooltip";
import { IconCTAButton } from "../../../quickRound/CTAButtonComponents";
import { TimeLineRenderer } from "./BalanceSheetAGComponent";
import { ProfitAndlossTimeLineRenderer } from "./ProfitAndLossAGComponent";
import { handleEventForTracking } from "../../../../amplitudeAnalytics";
import { useTableStateManagement } from "../../../rounds/TableHook";
import { TableId } from "../../../../constants/TableIdConstants";
import CloudSetting from "../../../../components/shared/CloudIcon";

function AGGridProfitAndLossTable({
  data,
  timelineType,
}: {
  data: any;
  timelineType: string;
}) {
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const currencySymbol = getCurrencySymbol();

  const [pageLoaded, setPageLoaded] = useState(false);

  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
    tableState,
  } = useTableStateManagement(
    gridApi,
    `${TableId.financeProfitAndLoss}${companyId}`,
    pageLoaded
  );

  if (timelineType === "Year") {
    data?.sort((a: any, b: any) => parseInt(b.year, 10) - parseInt(a.year, 10));
  } else if (timelineType === "Quarterly") {
    data?.sort((a: any, b: any) => {
      if (a.year !== b.year) {
        return b.year - a.year;
      } else {
        const quartersOrder = ["Q1", "Q2", "Q3", "Q4"];
        return (
          quartersOrder.indexOf(b.timeline) - quartersOrder.indexOf(a.timeline)
        );
      }
    });
  } else if (timelineType === "Monthly") {
    data?.sort((a: any, b: any) => {
      if (a.year === b.year) {
        // If years are equal, sort based on month in descending order
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return months.indexOf(b.timeline) - months.indexOf(a.timeline);
      } else {
        return b.year - a.year;
      }
    });
  }

  useEffect(() => {
    if (data && timelineType === "Year") {
      const newColumnDefs = data.map((template: any) => ({
        headerName: `${template.timeline} (${currencySymbol})`,
        field: template.timeline,
        cellRenderer: "timeline",
        filter: "agNumberColumnFilter",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipText",
        tooltipComponent: financeCustomToolTip,
        // Specify the field containing tooltip text
      }));
      setColumnDefs(newColumnDefs);
    } else if (data && timelineType === "Monthly") {
      const newColumnDefs = data.map((template: any) => ({
        headerName: `${template.year}-${template.timeline} (${currencySymbol})`,
        field: template.timeline,
        filter: "agNumberColumnFilter",
        cellRenderer: "timeline",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipText",
        tooltipComponent: financeCustomToolTip,
      }));
      setColumnDefs(newColumnDefs);
    } else if (data && timelineType === "Quarterly") {
      const newColumnDefs = data.map((template: any) => ({
        headerName: `${template.year}-${template.timeline} (${currencySymbol})`,
        field: template.timeline,
        filter: "agNumberColumnFilter",
        cellRenderer: "timeline",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipText",
        tooltipComponent: financeCustomToolTip,
      }));
      setColumnDefs(newColumnDefs);
    } else {
      setColumnDefs([]);
    }
  }, [data]);
  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const componentsRegistery = useMemo(
    () => ({
      timeline: memo(ProfitAndlossTimeLineRenderer),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: `Shareholder Name` });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    [data]
  );

  const getRowStyle = (params: any) => {
    let rowStyle;
    if (params.rowIndex % 2 === 0) {
      rowStyle = { background: "#f8f8f8" };
    } else {
      rowStyle = { background: "#ffffff" };
    }

    if (
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Revenue From Operations") ||
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Other Income") ||
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Total Revenue") ||
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Expenses") ||
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Total Expenses") ||
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Profit Before Tax") ||
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Tax Expense") ||
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Earnings Per Share")
    ) {
      rowStyle = {
        ...rowStyle,

        color: "black",
        fontWeight: 600,
      };
    }

    if (
      params.data.orgHierarchy[0] === "Total Revenue" ||
      params.data.orgHierarchy[0] === "Total Expenses"
    ) {
      rowStyle = { ...rowStyle, background: "#c6c6c6" };
    }
    return rowStyle;
  };

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    setPageLoaded(true);
    return {
      headerName: "Group Name",
      minWidth: 300,
      menuTabs: [],
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
      },
      pinned: true,
    };
  }, [data]);

  const getDataPath = useMemo<GetDataPath>(
    () => (data: any) => data.orgHierarchy,
    []
  );

  const currencyType = getCurrencyType();

  const rowData = useMemo(() => {
    if (!data) return [];

    return data.flatMap((template: any) => {
      const timeline = template.timeline;
      const domesticTurnover = Number(template.data.domesticTurnover);
      const exportTurnover = Number(template.data.exportTurnover);
      const totalRevenueFromOperations = Number(
        template.data.totalRevenueFromOperations
      );
      const costOfMaterialsConsumed = Number(
        template.data.costOfMaterialsConsumed
      );
      const employeeBenefitExpense = Number(
        template.data.employeeBenefitExpense
      );
      const financeCosts = Number(template.data.financeCosts);
      const depreciationAndAmortization = Number(
        template.data.depreciationAndAmortization
      );
      const others = Number(template.data.others);
      const totalExpenses = Number(template.data.totalExpenses);
      const currentTax = Number(template.data.currentTax);
      const deferredTax = Number(template.data.deferredTax);
      const totalTaxExpense = Number(template.data.totalTaxExpense);
      const basic = Number(template.data.basic);
      const diluted = Number(template.data.diluted);
      const profitBeforeTax = Number(template.data.profitBeforeTax);
      const otherIncome = Number(template.data.otherIncome);
      const totalRevenue = Number(template.data.totalRevenue);

      const totalRevenueFromOperationsTooltip = Number(
        template.data.totalRevenueFromOperations
      );
      return [
        {
          orgHierarchy: ["Revenue From Operations"],
          [timeline]: totalRevenueFromOperations,
        },
        {
          orgHierarchy: ["Revenue From Operations", "Domestic Turnover"],
          [timeline]: domesticTurnover,
        },
        {
          orgHierarchy: ["Revenue From Operations", "Export Turnover"],
          [timeline]: exportTurnover,
        },
        {
          orgHierarchy: ["Other Income"],
          [timeline]: otherIncome,
        },
        {
          orgHierarchy: ["Total Revenue"],
          [timeline]: totalRevenue,
        },
        {
          orgHierarchy: ["Expenses"],
          [timeline]: totalExpenses,
        },
        {
          orgHierarchy: ["Expenses", "Cost of Materials Consumed"],
          [timeline]: costOfMaterialsConsumed,
        },
        {
          orgHierarchy: ["Expenses", "Payroll"],
          [timeline]: employeeBenefitExpense,
        },
        {
          orgHierarchy: ["Expenses", "Depreciation and Amortization"],
          [timeline]: depreciationAndAmortization,
        },
        {
          orgHierarchy: ["Expenses", "others"],
          [timeline]: others,
        },
        {
          orgHierarchy: ["Expenses", "Finance Costs"],
          [timeline]: financeCosts,
        },
        {
          orgHierarchy: ["Total Expenses"],
          [timeline]: totalExpenses,
        },
        {
          orgHierarchy: ["Profit Before Tax"],
          [timeline]: profitBeforeTax,
        },
        {
          orgHierarchy: ["Tax Expense"],
          [timeline]: totalTaxExpense,
        },
        {
          orgHierarchy: ["Tax Expense", "Current Tax"],
          [timeline]: currentTax,
        },
        {
          orgHierarchy: ["Tax Expense", "Deffered Tax"],
          [timeline]: deferredTax,
        },
        {
          orgHierarchy: ["Tax Expense", "Total Tax Expense"],
          [timeline]: totalTaxExpense,
        },
        {
          orgHierarchy: ["Earnings Per Share"],
          [timeline]: "-",
        },
        {
          orgHierarchy: ["Earnings Per Share", "Basic"],
          [timeline]: basic,
        },
        {
          orgHierarchy: ["Earnings Per Share", "Diluted"],
          [timeline]: diluted,
        },
      ];
    });
  }, [data]);

  const groupedData = rowData.reduce((groups: any, item: any) => {
    const key = JSON.stringify(item.orgHierarchy);
    (groups[key] = groups[key] || []).push(item);
    return groups;
  }, {});

  const groupedArray = Object.values(groupedData);
  const combinedData = groupedArray.flatMap((group: any) => {
    const combinedGroup = Object.assign({}, ...group);

    return [combinedGroup];
  });

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.68) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 2.62) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.48) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.42) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "shareholderName") {
        onClickShareholderName(template.id);
      }
    }
  };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter();
  };

  useEffect(() => {
    const filterModel = gridApi.current?.api.getFilterModel();

    const filteredData = gridApi.current?.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);

    setFilteredRowData(filteredData);
    gridApi.current?.api.setFilterModel(tableState?.filterState);
  }, [gridApi, tableState?.filterState, tableState?.columnState, data]);

  return (
    <div>
      <HStack className="flex-col lg:flex-row justify-end lg:justify-end  py-2 lg:items-center items-start  bg-white rounded-md">
        <HStack className="justify-between w-full lg:w-auto lg:justify-end py-2 items-center gap-4 px-8">
          <CloudSetting
            tableId={`${TableId.financeProfitAndLoss}${companyId}`}
            isLoading={isSaving}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
          />
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <HStack className="gap-4">
            <IconCTAButton
              value={"Columns"}
              onClick={() => openToolPanel("columns")}
              iconName={"fluent:column-triple-edit-20-regular"}
              className={`px-4 font-medium items-center flex flex-row ${
                isColumnOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
            <IconCTAButton
              value={"Filters"}
              onClick={() => openToolPanel("filters")}
              iconName={"material-symbols:filter-alt"}
              className={`px-4 font-medium items-center flex flex-row ${
                isFilterOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
          </HStack>
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            ref={gridRef}
            onGridReady={(params) => {
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            onColumnMoved={uploadColumn}
            rowData={combinedData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            autoGroupColumnDef={autoGroupColumnDef}
            treeData={true}
            groupDefaultExpanded={-1}
            pagination={false}
            getDataPath={getDataPath}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={getRowStyle}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AGGridProfitAndLossTable;
