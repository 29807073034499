import * as Yup from "yup";

export const newShareholderValidationSchema = Yup.object({
  name: Yup.string().required().label("Name"),
  email: Yup.string().email().required().label("Email"),
  uniqueId: Yup.string().required().label("PAN"),
  type: Yup.string().required().label("Type"),
  residence: Yup.string().required().label("Residential Status"),
});

export const newInstrumentValidationSchema = Yup.object({
  securityName: Yup.string().required().label("Security Name"),
  securityType: Yup.string().required().label("Security Type"),
  conversionRatio: Yup.number().when("securityType", {
    is: (val: string) => val !== "Equity" && val !== "Note",
    then: Yup.number().required().label("Conversion Ratio"),
    otherwise: Yup.number().label("Conversion Ratio"),
  }),
  par: Yup.number().when("securityType", {
    is: (val: string) => val !== "Note",
    then: Yup.number().required().min(1).label("PAR"),
    otherwise: Yup.number().label("PAR"),
  }),
});

export const validationShareHolderSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  security: Yup.string().required().label("Security"),
  investmentCurrency: Yup.string().required().label("Investment Currency"),
  exchangeRate: Yup.number().moreThan(0).required().label("Exchange Rate"),
  sharePrice: Yup.number().when("securityType", {
    is: (val: string) => val !== "Note",
    then: Yup.number().positive().required().label("Share Price"),
    otherwise: Yup.number().label("Share Price"),
  }),
  numberOfShares: Yup.number().when("securityType", {
    is: (val: string) => val !== "Note",
    then: Yup.number()
      .integer()
      .min(1)
      .min(1)
      .required()
      .label("No. of Shares"),
    otherwise: Yup.number().label("No. of Shares"),
  }),
  amountInvested: Yup.number().positive().required().label("Amount Invested"),
  securityType: Yup.string().optional().label("Security Type"),
});

export const validationSecondaryShareHolderSchema = Yup.object().shape({
  numberOfShares: Yup.number().moreThan(0).required().label("Number of shares"),
  buyerName: Yup.string().required().label("Buyer Name"),
  transactionCurrency: Yup.string().required().label("Investment Currency"),
  exchangeRate: Yup.number().moreThan(0).required().label("Exchange Rate"),
  sellerName: Yup.string().required().label("Seller Name"),
  date: Yup.date().required().label("Date"),
  security: Yup.string().required().label("Security"),
  //dirty Commit distinctiveShareNumber: Yup.string()
  //   .required()
  //   .label("Distinctive Share Number"),

  amountInvested: Yup.number()
    .positive()
    .moreThan(0)
    .required()
    .label("Transaction Amount"),
});

export const validationBuybackShareHolderSchema = Yup.object().shape({
  name: Yup.string().required().label("Name"),
  noOfShares: Yup.number().positive().required().label("No of Shares"),
  securityClass: Yup.string().required().label("Security Class"),
  amount: Yup.number().positive().required().label("Amount"),
});

export const validationSplitSchema = Yup.object().shape({
  splitName: Yup.string().required().label("Split Name"),
  splitRatio: Yup.number().required().label("Split Number"),
  securityType: Yup.string().required().label("Security Type"),
  date: Yup.string().required().label("Round Size"),
});

export const validationSecondarySchema = Yup.object().shape({
  secondaryName: Yup.string().required().label("Secondary Name"),
  date: Yup.string().required().label("Date"),
});

export const validationBuybackSchema = Yup.object().shape({
  buybackName: Yup.string().required().label("Buyback Name"),
  date: Yup.string().required().label("Date"),
});

export const newEmployeeValidationSchema = Yup.object({
  name: Yup.string().required().label("Name"),
  email: Yup.string().email().label("Email"),
  uniqueId: Yup.string().label("PAN"),
  type: Yup.string().required().label("Type"),
  residence: Yup.string().label("Residential Status"),
});
