import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { Box, HStack } from "../../components/utils";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { useGetIndividualRoundDetail, useGetPaidUpCapitalDetails } from "../../queries/transactionRound";
import BCHeader from "../../shared/BCHeader";
import {
  ButtonSize,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { RoundHeaderCards } from "./RoundHeaderCard";
import IndividualNotes from "../../components/IndividualNotes";
import { IndividualRoundDetailModel } from "../../types/RoundsModel";
import DashBoardGenericRoundCaptable from "./DashBoardGenericRoundCaptable";
import { IndividualRoundCapTable } from "../../types/AllTransactionModel";
import { useAuthStore } from "../../store";
import {
  extractConvertibles,
  extractSecondaryTransaction,
  extractTransaction,
} from "../newQuickTransaction/ExtractionUtility";
import {
  PrimaryTransactionDataModel,
  SecondaryTransactionDataModel,
} from "../../types/quickRound";
import { NonPricedRoundConversion } from "../../modals/NonPricedRoundConversion";
import { SecurityTableDetail } from "../../types/SecurityModel";
import { roundHeaderCardList } from "../../constants/TransactionConstants";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { EditPpsAndValuation } from "../../modals/EditPpsAndValuation";
import { useInternalSwitchStateStore } from "../../store/searchDropDown";
import SearchAutoCompleteDropDown from "../../components/shared/SearchAutoCompleteDropDown";
import { SwitchDropDownModel } from "./RoundsAllTransactions";
import { DropDownModel } from "../../types/OnBoardDataModel";
import {
  useInvGetIndividualRoundDetail,
  useInvGetInstrumentAndShareHolder,
} from "../../queries/investorPortal";
import AGGridRoundsTable from "./RoundsAGTable";
import AGGridPrimaryRoundTransactionTable from "./PrimaryRoundTransactionAgTable";
import AGGridSecondaryRoundTransactionTable from "./SecondaryRoundTransactionAgTable";
import AGGridRoundTransactionTable from "./RoundsTransactionAgTable";
import NonPricedAgCaptable from "./NonPricedAGCapTable";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import { getCompanyCurrency } from "../../utils/currencyFormatter";
import ShareModal from "../share/ShareModal";
import {
  useSharedGetIndividualRoundDetail,
  useSharedGetInstrumentAndShareHolder,
} from "../../queries/sharedResouces";
import { useShareAuthStore } from "../../store/useShareAuthStore";
import { OutstandingConvertible } from "../../types/Modeling";

export default function RoundsOverview() {
  const { id, cId, token } = useParams();
  const pathName = window.location.href.split("/");
  const sharedPage = useMemo(() => pathName[3] === "share", [pathName]);
  const { shareId, shareCompanyId, shareResourceId } = useShareAuthStore();
  const eventId = id || "";
  const companyId = useAuthStore.getState().companyId || "";

  const navigate = useNavigate();
  const canDoTransactions = useCanUserAccess("create", "transaction");

  const [secondaryTransactions, setSecondaryTransaction] = useState<
    SecondaryTransactionDataModel[]
  >([]);
  const [primaryTransactions, setPrimaryTransactions] = useState<
    PrimaryTransactionDataModel[]
  >([]);
  const [convertibles, setConvertibles] = useState<OutstandingConvertible[]>(
    []
  );
  const transactionSwitchStore = useInternalSwitchStateStore();
  const [selectedTransaction, setSelectedTransaction] =
    useState<SwitchDropDownModel>({ name: "", id: "", type: "" });

  function getSelectedValue(transaction?: SwitchDropDownModel) {
    if (transaction) {
      if (cId) {
        handleEventForTracking({ eventName: "Inv Transactions Search" });
        navigate(
          `/inv/transaction/${transaction.type?.toLowerCase()}/${
            transaction.id
          }/${cId}/${token}`
        );
      } else {
        handleEventForTracking({ eventName: "Inv Transactions Search" });
        navigate(
          `/transactions/${transaction.type?.toLowerCase()}/${transaction.id}`
        );
      }
    }
  }

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: SecurityTableDetail[];
    index?: number;
    mode?: "Non Priced Round Conversion" | "Edit Valuation and PPS";
  }>({
    open: false,
  });

  const { isPlaceholderData, data: roundDetail } = sharedPage
    ? useSharedGetIndividualRoundDetail(
        shareResourceId ?? "",
        shareCompanyId ?? ""
      )
    : cId
    ? useInvGetIndividualRoundDetail(eventId, cId, token || "")
    : useGetIndividualRoundDetail(eventId);
  const {
    refetch: getInstrumentAndShareHolder,
    data: instrumentShareholderInfoData,
  } = sharedPage
    ? useSharedGetInstrumentAndShareHolder(
        shareId ?? "",
        shareResourceId ?? "",
        shareCompanyId ?? ""
      )
    : cId
    ? useInvGetInstrumentAndShareHolder(cId, token || "")
    : useGetInstrumentAndShareHolder(companyId, cId);
  const data = useShareAuthStore();
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  useEffect(() => {
    if (
      cId ||
      (shareholderAndInstrumentstore.shareholders.length === 0 &&
        shareholderAndInstrumentstore.instrumentClasses.length === 0)
    )
      getInstrumentAndShareHolder().then((data) => {
        shareholderAndInstrumentstore.setInstrumentClass(
          data?.data?.instrumentClasses || []
        );
        shareholderAndInstrumentstore.setShareholders(
          data?.data?.shareholders || []
        );
        shareholderAndInstrumentstore.setInstrumentClassSubTypes(
          data?.data?.instrumentClassSubTypes || {}
        );
      });
  }, []);
  const companyCurrency = getCompanyCurrency();
  useEffect(() => {
    if (roundDetail) {
      const primary = extractTransaction(
        roundDetail.transactions.primaryTransactions,
        shareholderAndInstrumentstore?.shareholders || [],
        shareholderAndInstrumentstore?.instrumentClasses || [],
        companyCurrency,
        roundDetail
      );
      const secondary = extractSecondaryTransaction(
        roundDetail.transactions.secondaryTransactions,
        shareholderAndInstrumentstore?.shareholders || [],
        shareholderAndInstrumentstore?.instrumentClasses || []
      );
      const convertibles = extractConvertibles(
        roundDetail.transactions.convertibleDetails || [],
        shareholderAndInstrumentstore?.shareholders || [],
        shareholderAndInstrumentstore?.instrumentClasses || []
      );
      if (!isPlaceholderData)
        setSelectedTransaction({
          name: roundDetail?.name,
          id: roundDetail.id,
          type: roundDetail?.type,
        });
      setPrimaryTransactions(primary);
      setSecondaryTransaction(secondary);
      setConvertibles(convertibles);
    }
  }, [shareholderAndInstrumentstore, roundDetail]);

  const _captableData: IndividualRoundCapTable[] = roundDetail?.capTable || [];

  const handleConvertRound = () => {
    if (roundDetail?.nonPricedSecurityDetails) {
      setDialog({
        open: true,
        data: roundDetail.nonPricedSecurityDetails,
        mode: "Non Priced Round Conversion",
      });
    }
  };

  const [showShare, setShowShare] = useState(false);

  return (
    <div
      className={`${cId ? "bg-white px-4" : ""} ${
        isPlaceholderData ? "loading" : "min-h-full"
      }`}
    >
      {!sharedPage && (
        <HStack className="justify-between min-h-[45px] items-center">
          {showShare && (
            <ShareModal
              resourceId={eventId}
              resourceType={"ROUND TRANSACTION"}
              close={() => setShowShare(false)}
            />
          )}
          <BCHeader
            className="items-baseline placeholderLoader"
            bcTitle="Transactions"
            bcSubTitle={roundDetail?.name ?? "Round Details"}
            cin={cId}
            token={token}
          />
          <HStack className="gap-4">
            {canDoTransactions && !cId && (
              <SecondaryCTAButton
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setShowShare(true);
                }}
              >
                Share
              </SecondaryCTAButton>
            )}
            {canDoTransactions && !cId && (
              <SecondaryCTAButton
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setDialog({
                    open: true,
                    mode: "Edit Valuation and PPS",
                  });
                }}
              >
                Edit
              </SecondaryCTAButton>
            )}

            {!roundDetail?.isPricedRound &&
              canDoTransactions &&
              !cId &&
              (roundDetail?.nonPricedSecurityDetails?.length || 0) > 0 && (
                <SecondaryCTAButton
                  buttonSize={ButtonSize.SMALL}
                  onClick={handleConvertRound}
                >
                  Convert Round
                </SecondaryCTAButton>
              )}
            {selectedTransaction ? (
              <Box className="w-48 h-8 ml-4 bg-white rounded-md">
                <SearchAutoCompleteDropDown
                  placeholder="Switch Transaction"
                  data={
                    transactionSwitchStore.transaction as SwitchDropDownModel[]
                  }
                  getSelectedValue={getSelectedValue}
                  selectedOption={selectedTransaction as SwitchDropDownModel}
                  field={"name" as keyof SwitchDropDownModel}
                />
              </Box>
            ) : (
              ""
            )}
          </HStack>
        </HStack>
      )}

      <Dialog open={dialog.open} maxWidth="md">
        {dialog.mode === "Non Priced Round Conversion" &&
        roundDetail?.nonPricedSecurityDetails ? (
          <NonPricedRoundConversion
            securities={roundDetail?.nonPricedSecurityDetails || []}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : (
          <div></div>
        )}
        {dialog.mode === "Edit Valuation and PPS" ? (
          <EditPpsAndValuation
            pps={roundDetail?.pps || 0}
            valuation={roundDetail?.postMoney || 0}
            paymentType={roundDetail?.paymentType || null}
            issuanceType={roundDetail?.issuanceType || null}
            eventId={roundDetail?.id || ""}
            authorizedEquity={roundDetail?.authorizedEquity}
            authorizedPreference={roundDetail?.authorizedPreference}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : (
          <div></div>
        )}
      </Dialog>

      <div className="max-w-full pb-8 mx-auto">
        {roundDetail && (
          <dl className="grid grid-cols-1 gap-8 mt-5 sm:grid-cols-2 lg:grid-cols-4">
            {roundHeaderCardList.map((headertype) => (
              <RoundHeaderCards
                key={headertype}
                roundDetail={
                  roundDetail as unknown as IndividualRoundDetailModel
                }
                headerType={headertype}
              />
            ))}
          </dl>
        )}
      </div>
      <AGGridRoundTransactionTable
        isInvestorPortal={!!cId || sharedPage}
        isPreviewOnly={true}
        secondaryTransactionProps={secondaryTransactions}
        primaryTransactionProps={primaryTransactions}
        convertibles={convertibles}
      />

      <AGGridRoundsTable
        capTableData={_captableData}
        isInvestorPortal={!!cId || sharedPage}
        roundName={roundDetail?.name || ""}
        eventId={eventId}
        mode={"round"}
      />
      {roundDetail?.roundDescription && (
        <IndividualNotes data={roundDetail?.roundDescription || ""} />
      )}
    </div>
  );
}
