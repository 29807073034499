export const initialCompanyDetail = {
  nameOfTheCompany: "",
  dippCertificateNumber: "",
  cinNumber: "",
  typeOfTheCompany: "",
  companyCurrency: "INR - ₹",
  dateOfIncorporation: "",
  corporateOfficeAddress: "",
  businessDescription: "",
  companyEmailId: "",
  companyWebsite: "",
  companyLogoPath: "",
  doingBusinessAs: "",
  business: "",
  industry: [],
  category: [],
  nic: "",
  founders: [],
  // directors: [],
  capitalDetails: {
    equityAuthorizedCapital: 12,
    preferenceAuthorizedCapital: 123,
    equityPaidUpCapital: 123,
    preferencePaidUpCapital: 234,
  },
  valuationDetails: [],
  splitFilter: false,
};
