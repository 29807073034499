import _ from "lodash";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, HStack } from "../../components/utils";
import {
  useDeleteOnGoingTransaction,
  useDiscardQuickRound,
  useDiscardQuickRound2,
  useGetAllTransactions,
  useGetOnGoingTransaction,
} from "../../queries/transactionRound";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";
import { RoundHeaderCards } from "./RoundHeaderCard";
import { IndividualRoundDetailModel } from "../../types/RoundsModel";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { AllTransactionModel } from "../../types/AllTransactionModel";
import {
  ButtonSize,
  IconButtonType,
  IconCTAButton,
  IconPlacement,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { formatDate } from "../../utils/date";
import {
  extractAllTransaction,
  getHeaderType,
} from "../../utils/transactionUtils";
import { useAllTransactionTableFilterState } from "../../store/AllTransactionFilterStore";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { useInternalSwitchStateStore } from "../../store/searchDropDown";
import {
  useInvGetAllTransactions,
  useInvGetInstrumentAndShareHolder,
} from "../../queries/investorPortal";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import AgGridTransactionTable from "./TransactionAGTable";
import { checkTypeOfRound } from "./RoundsUtility";
import Loader from "../../shared/Loader";
import {
  initialRoundState,
  useQuickRoundTransactionStore,
} from "../../store/useQuickTransactionStore";
import { useConversionStore } from "../../store/conversionStore";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import AlertDialog from "../../components/shared/AlertDialog";
import DeleteModal from "../modeling/DeleteModal";

export interface SwitchDropDownModel {
  name: string;
  type: string;
  id: string;
}

export default function RoundsAllTransactions() {
  const navigate = useNavigate();
  const { cId, token } = useParams();
  const canReadRights = useCanUserAccess("read", "rights");
  const companyId = useAuthStore.getState().companyId || "";
  const canDoTransactions = useCanUserAccess("create", "transaction");
  const transactionSwitchStore = useInternalSwitchStateStore();

  const { setState: setTemplate } = useQuickRoundTransactionStore();

  const [alltransactions, setAlltransactions] = useState<AllTransactionModel[]>(
    []
  );
  const allTransactionFilterStore = useAllTransactionTableFilterState();

  const [headerData, setHeaderData] = useState<IndividualRoundDetailModel[]>();

  const { isPlaceholderData, data: _alltransactions } = cId
    ? useInvGetAllTransactions(cId, token || "")
    : useGetAllTransactions();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });
  const { refetch, data: onGoingTransaction } =
    useGetOnGoingTransaction(companyId);
  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);
  useEffect(() => {
    if (!cId) refetch();
  }, []);
  const { mutate: discardQuickRound } = useDiscardQuickRound2();
  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    setTemplate(initialRoundState);
    deleteOnGoingTransaction(companyId);
  };

  useEffect(() => {
    const dropDownOptions: SwitchDropDownModel[] = [];
    const allTranactionsData =
      _alltransactions?.map((transaction) => {
        if (checkTypeOfRound(transaction.transactionType)) {
          const transactionName =
            transaction.transactionType !== "split"
              ? transaction.name
              : transaction.name + transaction.splitRatio;
          dropDownOptions.push({
            name: transactionName,
            type: transaction.transactionType,
            id: transaction.id,
          });
        }

        return {
          ...transaction,
          dateOfTransaction: formatDate(transaction.dateOfTransaction),
        };
      }) || [];
    transactionSwitchStore.setTransaction(dropDownOptions);
    setAlltransactions(allTranactionsData);
  }, [_alltransactions]);

  useEffect(() => {
    const headers = extractAllTransaction(alltransactions);
    headers.filter(
      (header) =>
        allTransactionFilterStore.transactionType.toLowerCase() ===
          header.type ||
        allTransactionFilterStore.transactionType.toLowerCase() ===
          "All".toLowerCase()
    );
    setHeaderData(headers);
  }, [alltransactions, allTransactionFilterStore.transactionType]);

  const { refetch: getInstrumentAndShareHolder } = cId
    ? useInvGetInstrumentAndShareHolder(cId, token || "")
    : useGetInstrumentAndShareHolder(companyId, cId);

  const shareholderAndInstrumentstore = useShareholderInstrumentStore();
  const conversionStore = useConversionStore();
  useEffect(() => {
    getInstrumentAndShareHolder().then((data) => {
      shareholderAndInstrumentstore.setInstrumentClass(
        data?.data?.instrumentClasses || []
      );
      shareholderAndInstrumentstore.setShareholders(
        data?.data?.shareholders || []
      );
      shareholderAndInstrumentstore.setInstrumentClassSubTypes(
        data?.data?.instrumentClassSubTypes || {}
      );
    });
  }, []);
  return (
    <div
      className={`${cId ? "bg-white px-4" : ""} ${
        isPlaceholderData ? "loading" : "min-h-full"
      }`}
    >
      <Dialog open={dialog.open} maxWidth="md">
        <DeleteModal
          onPrimaryAction={() => {
            handleDiscard();
            setDialog({ open: false });
          }}
          onSecondaryAction={() => setDialog({ open: false })}
          data={
            "Are you sure you want to delete the transaction? Data will be permanently removed. This action cannot be undone."
          }
        />
      </Dialog>
      <HStack className="items-center justify-between">
        <BCHeader className="items-baseline" bcTitle="Transactions" />
        <HStack className="flex-col items-center gap-4 md:flex-row">
          {!cId && canReadRights && (
            <SecondaryCTAButton
              buttonSize={ButtonSize.SMALL}
              onClick={
                cId
                  ? () => {}
                  : () => {
                      navigate("/rights/overview");
                    }
              }
            >
              View Rights
            </SecondaryCTAButton>
          )}
          {canDoTransactions && !cId && (
            <AddTransaction
              actions={[
                {
                  name: "Round",
                  disabled: onGoingTransaction,
                },
                {
                  name: "Secondary",
                  disabled: onGoingTransaction,
                },
                {
                  name: "Buyback",
                  disabled: onGoingTransaction,
                },
                {
                  name: "Bonus",
                  disabled: onGoingTransaction,
                },
                {
                  name: "Split",
                  disabled: onGoingTransaction,
                },
                {
                  name: "ESOP Exercise",
                  disabled: onGoingTransaction,
                },
                {
                  name: "Ongoing Round",
                  disabled: !onGoingTransaction,
                },
                {
                  name: "Discard Ongoing",
                  disabled: !onGoingTransaction,
                },
              ]}
              onAction={(action) => {
                if (action.name !== "Ongoing Round") {
                  conversionStore.reset();
                  setTemplate(initialRoundState);
                }
                if (!onGoingTransaction) {
                  if (action.name === "ESOP Exercise") {
                    setTemplate(initialRoundState);
                    navigate(`/transactions/exercise/create`);
                  } else {
                    setTemplate(initialRoundState);
                    navigate(
                      `/transactions/${action.name.toLowerCase()}/create`
                    );
                  }
                } else if (action.name === "Discard Ongoing") {
                  setDialog({
                    open: true,
                  });
                } else {
                  const path = `/transactions/${onGoingTransaction.transactionType.toLowerCase()}/create`;
                  setTemplate(initialRoundState);
                  navigate(`${path}`);
                }
              }}
            />
          )}
        </HStack>
      </HStack>
      <main className="">
        <dl className="flex flex-row gap-8 pb-8 mt-5 overflow-x-scroll scrollbar-hide">
          {headerData?.map((header) => (
            <div
              key={header.type}
              className="hover:cursor-pointer"
              onClick={() => {
                if (cId) {
                  navigate(
                    `/inv/transaction/${header.type?.toLowerCase()}/${
                      header.id
                    }/${cId}/${token}`
                  );
                  handleEventForTracking({
                    eventName: `Inv Transactions Card`,
                  });
                } else {
                  navigate(
                    `/transactions/${header.type?.toLowerCase()}/${header.id}`
                  );
                  handleEventForTracking({ eventName: `Transactions Card` });
                }
              }}
            >
              <RoundHeaderCards
                roundDetail={header}
                headerType={getHeaderType(header.type || "")}
              />
            </div>
          )) || <></>}
        </dl>
      </main>

      {isPlaceholderData ? (
        <Loader />
      ) : (
        <AgGridTransactionTable
          cid={cId || ""}
          token={token ?? ""}
          transactionTableData={alltransactions || []}
        />
      )}
    </div>
  );
}

function AddTransaction({
  actions,
  onAction,
}: {
  actions: Action[];
  onAction: (action: Action) => void;
}) {
  return (
    <CTADropdown
      className="items-center"
      dropdownClassName="bottom-auto"
      actions={actions}
      onAction={onAction}
      render={() => (
        <div className="h-8 pt-[1px] bg-primary rounded-sm">
          <IconCTAButton
            value={"Add Transaction"}
            buttonType={IconButtonType.FILLED}
            iconName={"ri:arrow-drop-down-line"}
            buttonSize={ButtonSize.SMALL}
            iconPlacement={IconPlacement.RIGHT}
          />
        </div>
      )}
    />
  );
}
