import { boardMeetingSchema } from "../types/BoardMeetingsModels";

export const TableId = {
  directorTable: "DirectorTable",
  boardMeetingTable: "BoardMeetingTable",
  dashboardCaptable: "DashboardCaptable",
  allTransactionTable: "AllTransactionTable",
  transactionTable: "TransactionTable",
  transactionCaptable: "TransactionCaptable",
  shareHolderSummaryTable: "ShareHolderSummaryTable",
  shareHolderTransactionTable: "ShareHolderTransactionTable",
  shareCertificateTable: "ShareCertificateTable",
  securitySummaryTable: "SecuritySummaryTable",
  securityClassTable: "SecurityClassTable",
  securityTransactionTable: "SecurityTransactionTable",
  termsheetSummaryTable: "TermsheetSummaryTable",
  termsheetTable: "TermsheetTable",
  roundExistingInvestorTable: "RoundExistingInvestorTable",
  roundNewInvestorTable: "RoundNewInvestorTable",
  roundSecondaryInvestorTable: "RoundSecondaryInvestorTable",
  roundModelCaptable: "RoundModelCaptable",
  financeBalanceSheet: "FinanceBalanceSheet",
  financeProfitAndLoss: "FinanceProfitAndLoss",
};
