import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { useNavigate } from "react-router";
import ReactDOMServer from "react-dom/server";
import { Dialog } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { Box, HStack, useGetCompanyName } from "../../components/utils";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import { useAgGridTableState } from "../../store/agGridTableStore";
import { EmptyTable } from "../../components/shared/EmptyTable";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import { EmployeeExerciseTransaction } from "./RoundTransactionModel";
import {
  AmountRender,
  DateRender,
  EmployeeNameRender,
  NumberOfSharesRender,
  SecurityRender,
  ExercisePriceRender,
} from "./AddEsopExerciseAgComponent";
import AddESOPExercisePopup from "./AddESOPExercisePopup";

function AgGridQuickESOPExerciseTable() {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);

  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const esopExerciseStore = useNewShareholderInstrumentStore();
  useEffect(() => {
    const count = gridApi.current?.api.getDisplayedRowCount();

    if (count !== undefined) {
      setDisplayedRowCount(count);
    }
  }, [esopExerciseStore.esopExercises]);

  const [addDialog, setAddDialog] = useState<{
    open: boolean;
    name?: string;
    data?: any;
  }>({
    open: false,
  });

  const handleAction = (
    action: Action,
    esopExercise: EmployeeExerciseTransaction,
    index: number
  ) => {
    if (action.name === "Delete") {
      const esopExercises = esopExerciseStore.esopExercises.slice();
      esopExercises.splice(index, 1);
      esopExerciseStore.setEsopExercises(esopExercises);
      toast("Transaction deleted Successfully!", {
        type: "error",
        autoClose: 2000,
      });
    } else if (action.name === "Edit") {
      setAddDialog({
        open: true,
        data: esopExercise,
      });
    }
  };
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.quickEsopExerciseColumnModel);
      setFilterSetting(data.data?.quickEsopExerciseFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        quickEsopExerciseColumnModel: columnState,
        quickEsopExerciseFilterModel: filterState,
      },
    });
  };

  const componentsRegistery = useMemo(
    () => ({
      employee: memo(EmployeeNameRender),
      numberOfShares: memo(NumberOfSharesRender),
      security: memo(SecurityRender),
      amount: memo(AmountRender),
      exercisePrice: memo(ExercisePriceRender),
      date: memo(DateRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: `Shareholder Name` });
    navigate(`/shareholders/individual/${id}`);
  };
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Employee Name",
        field: "companyInvestorId",
        cellRenderer: "employee",
        initialWidth: 250,
        cellStyle: tableCellStyle,
        sortable: false,
        filter: "agSetColumnFilter",
        valueGetter: (params) =>
          shareholderAndInstrumentstore.shareholders.find(
            (shareholder) => params.data.companyInvestorId === shareholder.id
          )?.name || "",
        valueFormatter: (params) =>
          shareholderAndInstrumentstore.shareholders.find(
            (shareholder) => params.data.companyInvestorId === shareholder.id
          )?.name || "",
        tooltipField: "tooltipName",
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: `Number Of Shares`,
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "newShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmount",
      },
      {
        headerName: "Security",
        field: "security",
        filter: "agSetColumnFilter",
        cellRenderer: "security",
        cellStyle: tableCellStyle,
        sortable: false,
        valueGetter: (params) =>
          shareholderAndInstrumentstore.instrumentClasses.find(
            (instrument) => params.data.instrumentClassId === instrument.id
          )?.name || "",
        valueFormatter: (params) =>
          shareholderAndInstrumentstore.instrumentClasses.find(
            (instrument) => params.data.instrumentClassId === instrument.id
          )?.name || "",
        tooltipField: "tooltipSecurityClass",
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: `Exercise Price (${currencySymbol})`,
        field: "exercisePrice",
        filter: "agNumberColumnFilter",
        cellRenderer: "exercisePrice",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmount",
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "12px", "line-height": "10px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );
  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      esopExerciseStore?.esopExercises.map((template, index) => ({
        employeeName: template.employeeName,
        companyInvestorId: template.companyInvestorId,
        date: template.date,
        amount: template.amount,
        exercisePrice: template.exercisePrice,
        isExistingShareholder: template.isExistingShareholder,
        numberOfShares: template.numberOfShares,
        instrumentClassId: template.instrumentClassId,
        currencySymbol,
        currencyType,
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Edit",
                disabled: false,
              },
              {
                name: "Delete",
                disabled: false,
              },
            ]}
            onAction={(action) => handleAction(action, template, index)}
          />
        ),
      })),
    [esopExerciseStore.esopExercises]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 1.8) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "shareholderName") {
        onClickShareholderName(template.id);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };
  return (
    <div>
      <HStack className="flex-col lg:flex-row justify-start lg:justify-between  py-2 lg:items-center items-start  bg-white rounded-md"></HStack>
      <HStack className="justify-between w-full">
        <Dialog open={addDialog.open} maxWidth="md">
          <AddESOPExercisePopup
            onPrimaryAction={() =>
              setAddDialog({ open: false, name: "Secondary" })
            }
            onSecondaryAction={() =>
              setAddDialog({ open: false, name: "Secondary" })
            }
            data={addDialog.data}
            mode={"Edit"}
          />
        </Dialog>
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={ReactDOMServer.renderToString(
              <EmptyTable
                header="No Transaction"
                subHeader="Get started by adding a new transaction"
              />
            )}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AgGridQuickESOPExerciseTable;
