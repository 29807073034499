import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Input, Label } from "../../components/shared/InputField";
import { Box, Error, HStack, VStack } from "../../components/utils";
import {
  useDeleteOnGoingTransaction,
  usePostOnGoingTransaction,
  useDiscardQuickRound2,
  usePostTransactionEsopExerciseDetails,
} from "../../queries/transactionRound";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import {
  EsopExerciseDetails,
  EsopExerciseModel,
} from "./RoundTransactionModel";
import { useAuthStore } from "../../store";
import { useQuickEsopExerciseTransactionStore } from "../../store/useQuickTransactionStore";
import AlertDialog from "../../components/shared/AlertDialog";
import { Select } from "../../components/shared/Select";
import FileInput from "../../components/shared/FileInput";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import AgGridQuickESOPExerciseTable from "./AddESOPExerciseAgTable";
import AddESOPExercisePopup from "./AddESOPExercisePopup";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import AddShareholderPopup from "./AddShareholderPopup";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import Tooltip from "../../components/shared/Tooltip";

function QuickAddEsopExercise() {
  const companyId = useAuthStore.getState().companyId || "";

  const {
    state: template,
    setState: setTemplate,
    reset: resetTemplate,
  } = useQuickEsopExerciseTransactionStore();
  const pathName = window.location.href.split("/");
  const roundType = pathName[4];

  const esopExerciseStore = useNewShareholderInstrumentStore();

  const { data: instrumentShareholderInfoData } =
    useGetInstrumentAndShareHolder(companyId);
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();
  useEffect(() => {
    shareholderAndInstrumentstore.setInstrumentClass(
      instrumentShareholderInfoData?.instrumentClasses || []
    );
    shareholderAndInstrumentstore.setShareholders(
      instrumentShareholderInfoData?.shareholders || []
    );
    shareholderAndInstrumentstore.setInstrumentClassSubTypes(
      instrumentShareholderInfoData?.instrumentClassSubTypes || {}
    );
  }, []);

  const { mutate: postEsopExercise } =
    usePostTransactionEsopExerciseDetails(companyId);

  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);
  const { mutate: postOnGoingTransaction } = usePostOnGoingTransaction();

  const initialValues: EsopExerciseDetails = {
    esopExerciseName: template?.esopExerciseDetails?.esopExerciseName || "",
    date: template?.esopExerciseDetails?.date || "",
    adjustEsopPool: template?.esopExerciseDetails?.adjustEsopPool || false,
    employeeExercises: template?.esopExerciseDetails?.employeeExercises || [],
  };
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const [esopExerciseDialog, setEsopExerciseDialog] = useState<{
    open: boolean;
    message?: string;
    data?: any;
    name: "esopExercise" | "employee" | "";
  }>({
    open: false,
    name: "",
  });

  const navigate = useNavigate();
  const { mutate: discardQuickRound } = useDiscardQuickRound2();
  const displayDiscardQuickRoundPopUp = () => {
    setDialog({
      open: true,
      message: `Do you want to discard the current ongoing quick ${roundType} ?`,
    });
  };

  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    deleteOnGoingTransaction(companyId);
    esopExerciseStore.setEsopExercises([]);
    navigate("/transactions/allTransactions");
  };

  const esopExerciseDetailsValidationSchema = Yup.object({
    esopExerciseName: Yup.string().required().label("ESOP Exercise Name"),
    date: Yup.date().required().label("Date"),
    adjustEsopPool: Yup.boolean().required().label("Adjust ESOP Pool"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: esopExerciseDetailsValidationSchema,
    initialValues,
    onSubmit: (values) => {
      const esopExercises = esopExerciseStore.esopExercises || [];

      if (esopExercises.length === 0) {
        toast("No ESOP Exercise Allotments", {
          type: "error",
          autoClose: 2000,
        });
        return;
      }

      const esopExercise: EsopExerciseModel = {
        companyId,
        esopExerciseDetails: {
          esopExerciseName: values.esopExerciseName,
          date: values.date,
          adjustEsopPool: values.adjustEsopPool,
          employeeExercises: esopExercises.map((esopExercise) => ({
            employeeName: esopExercise.employeeName,
            companyInvestorId: esopExercise.companyInvestorId,
            date: esopExercise.date,
            amount: esopExercise.amount,
            exercisePrice: esopExercise.exercisePrice,
            isExistingShareholder: esopExercise.isExistingShareholder,
            numberOfShares: esopExercise.numberOfShares,
            instrumentClassId: esopExercise.instrumentClassId,
          })),
        },
      };
      postEsopExercise(
        { esopExercise: esopExercise.esopExerciseDetails! },
        {
          onSuccess: (data) => {
            postOnGoingTransaction({
              ...esopExercise,
              eventId: data,
            });
            setTemplate({
              ...esopExercise,
              eventId: data,
              stepNo: 2,
            });
            toast("ESOP Exercise Details Updated Successfully!", {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: (err: any) => {
            toast(err.response.data.errorMessage, {
              type: "error",
              autoClose: 2000,
            });
          },
        }
      );
    },
  });

  return (
    <VStack>
      <Box className="">
        <VStack className="bg-white rounded-lg p-6">
          <HStack className="justify-between pb-8">
            <VStack>
              <p className="text-xl font-semibold text-gray-dark">
                ESOP Exercise
              </p>
              <Box className="text-xs font-medium text-gray-light">
                Fill the ESOP Exercise details and record employee exercises
              </Box>
            </VStack>
          </HStack>

          <Dialog open={dialog.open} maxWidth="md">
            <AlertDialog
              onClose={() => setDialog({ open: false })}
              open={dialog.open}
              message={dialog.message}
              onPrimaryAction={handleDiscard}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          </Dialog>
          <VStack className="gap-8 -mt-4">
            <HStack className="justify-start gap-8">
              <VStack className="w-1/3">
                <Label className="py-2 mr-2 text-sm font-medium">
                  ESOP Exercise Name
                </Label>
                <VStack>
                  <Input
                    type="text"
                    className="w-60"
                    placeholder="Enter ESOP Exercise Name"
                    {...formik.getFieldProps("esopExerciseName")}
                  />
                  {formik.touched?.esopExerciseName &&
                    formik.errors?.esopExerciseName && (
                      <Error text={formik.errors?.esopExerciseName} />
                    )}
                </VStack>
              </VStack>
              <VStack className="w-1/3">
                <Label className="py-2 lg:w-fit w-32 mr-2 text-sm font-medium whitespace-nowrap">
                  ESOP Exercise Date
                </Label>
                <VStack>
                  <Input
                    type="date"
                    className="w-60"
                    {...formik.getFieldProps("date")}
                  />
                  {formik.touched?.date && formik.errors?.date && (
                    <Error text={formik.errors?.date} />
                  )}
                </VStack>
              </VStack>
              <VStack className="w-1/3">
                <HStack className="gap-1 items-center">
                  <Label className="py-2  mr-2 text-sm font-medium whitespace-nowrap">
                    Adjust ESOP Pool
                  </Label>
                  <Tooltip
                    text={`Toggling this option will automatically reduce the ESOP pool based on the allotment made and adjust the FDB percentage accordingly.`}
                    _className="w-64"
                  >
                    <Icon
                      icon={"simple-line-icons:info"}
                      width={12}
                      className="cursor-pointer"
                    />
                  </Tooltip>
                </HStack>
                <VStack className="">
                  <SwitchButton
                    className="text-sm font-medium items-center"
                    value={formik.values.adjustEsopPool}
                    label=""
                    onClick={() => {
                      formik.setFieldValue(
                        "adjustEsopPool",
                        !formik.values.adjustEsopPool
                      );
                    }}
                  />
                  {formik.touched?.adjustEsopPool &&
                    formik.errors?.adjustEsopPool && (
                      <Error text={formik.errors?.adjustEsopPool} />
                    )}
                </VStack>
              </VStack>
            </HStack>
          </VStack>
        </VStack>

        <VStack className="bg-white rounded-lg px-8 mt-16">
          <HStack className="pt-10 pb-4">
            <Dialog open={esopExerciseDialog.open} maxWidth="md">
              {esopExerciseDialog.open &&
              esopExerciseDialog.name === "esopExercise" ? (
                <AddESOPExercisePopup
                  onPrimaryAction={() =>
                    setEsopExerciseDialog({ open: false, name: "esopExercise" })
                  }
                  onSecondaryAction={() =>
                    setEsopExerciseDialog({ open: false, name: "esopExercise" })
                  }
                  data={esopExerciseDialog.data}
                  mode={"Add"}
                />
              ) : esopExerciseDialog.open &&
                esopExerciseDialog.name === "employee" ? (
                <AddShareholderPopup
                  onPrimaryAction={() =>
                    setEsopExerciseDialog({ open: false, name: "employee" })
                  }
                  onSecondaryAction={() =>
                    setEsopExerciseDialog({ open: false, name: "employee" })
                  }
                  data={esopExerciseDialog.data}
                  isEmployee={true}
                />
              ) : (
                <></>
              )}
            </Dialog>
            <HStack className="w-full gap-4">
              <p className={"text-lg"}>ESOP Exercise Allotments</p>
            </HStack>

            <HStack className="gap-4">
              <SecondaryCTAButton
                event-name="Add Employee Button"
                className="text-xs2 h-8 whitespace-nowrap"
                onClick={() => {
                  setEsopExerciseDialog({ open: true, name: "employee" });
                }}
              >
                Add Employee
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Add ESOP Exercise Allotment Button"
                className="text-xs2 h-8 whitespace-nowrap"
                onClick={() => {
                  setEsopExerciseDialog({ open: true, name: "esopExercise" });
                }}
              >
                Add Exercise Allotment
              </PrimaryCTAButton>
            </HStack>
          </HStack>
          <AgGridQuickESOPExerciseTable />
        </VStack>

        <HStack className="justify-between gap-4 mt-8">
          <HStack>
            <SecondaryCTAButton
              type="reset"
              event-name="Discard ESOP Exercise"
              className="text-red-500 shadow-box"
              onClick={displayDiscardQuickRoundPopUp}
            >
              Discard
            </SecondaryCTAButton>
          </HStack>
          <PrimaryCTAButton
            event-name="Create ESOP Exercise"
            onClick={() => {
              formik.handleSubmit();
            }}
            className="min-w-max"
            type="submit"
          >
            Create ESOP Exercises
          </PrimaryCTAButton>
        </HStack>
      </Box>
    </VStack>
  );
}

export default QuickAddEsopExercise;
