import { SecondaryTransaction } from "../pages/newQuickTransaction/RoundTransactionModel";
import { SecurityTableDetail } from "./SecurityModel";

export interface SecurityTypeModel {
  securityType: string;
  parValue: number;
  premium: number;
}

export interface RoundDetailModel {
  roundId: string; //uuid
  roundType: string; //what type of round is it secondary, round ,bonus,buyback
  roundName: string; //name of the round
  roundIdentifier: string; //short id for round
}

export interface AllTransactionModel {
  id: string;
  name: string;
  identifier: string;
  transactionType: string;
  issuanceType: string;
  dateOfTransaction: string;
  valuation: number;
  dilution: number;
  securityTypes: SecurityTypeModel[];
  amount: number;
  leadInvestor: string;
  paymentType: string;
  splitRatio: string;
  bonusRatio: string;
  pps: number;
  securityClass: string[];
  noOfShares: number;
  isPricedRound?: boolean;
  roundPPS: number;
  debtSecurityForPPS: string;
  currency?: string;
  amountInvestedInCurrency?: number;
  exchangeRate?: number;
  transactionPrice?: number[];
  lastUpdatedDate?: string;
}

//-------------------Bonus Model-----------------------

export interface IndividualBonusCapTable {
  id: string;
  rounds: RoundDetailModel;
  preHoldingCommonShares: number;
  preHoldingPrefShares: number;
  preHoldingFdbShares: number;
  preHoldingDebentures: number;
  preHoldingFdbPercentage: number;
  postHoldingCommonShares: number;
  postHoldingPrefShares: number;
  postHoldingFdbShares: number;
  postHoldingDebentures: number;
  postHoldingFdbPercentage: number;
  shareholderName: string;
  shareholderType: string;
}

export interface IndividualBonusModel {
  id: string;
  name: string;
  identifier: string;
  type: string;
  amountRaised: number;
  dateOfTransaction: string;
  numberOfShares: number;
  pps: number;
  oldPps: number;
  newBonusShares: number;
  newFdbShares: number;
  roundDescription: string;
  capTable: IndividualBonusCapTable[];
}

// -------------------------Buyback Model--------------------------

export interface IndividualBuybackCapTable {
  id: string;
  shareholderName: string;
  shareholderType: string;
  preHoldingFdbShares: number;
  preHoldingFdbPercentage: number;
  postHoldingFdbShares: number;
  postHoldingFdbPercentage: number;
  numberOfShares: number; // clear what share is this with  jayant
}

export interface IndividualBuybackModel {
  id: string;
  name: string;
  identifier: string;
  type: string;
  amountRaised: number;
  dateOfTransaction: string;
  numberOfShares: number;
  pps: number;
  securityTypes: string[];
  dilution: number;
  roundDescription: string;
  capTable: IndividualBuybackCapTable[];
}

// ---------------------Secondary Model-----------------------------

export interface IndividualSecondaryCapTable {
  id: string;
  shareholderName: string;
  shareholderType: string;
  rounds: RoundDetailModel[];
  preHoldingFdbShares: number;
  preHoldingFdbPercentage: number;
  postHoldingFdbShares: number;
  postHoldingFdbPercentage: number;
  transactionValue: number;
  totalAmountInvested: number;
  netWorth: number;
}

export interface SecondaryTransactionDataModel {
  buyerName: string;
  sellerName: string;
  investorType?: string;
  security: string;
  image?: string;
  sharePrice?: number;
  instrumentClassId?: string;
  buyerInvestorId?: string;
  sellerInvestorId?: string;
  numberOfShares: number;
  amountInvested: number;
  par?: number;
  conversionRatio?: string;
  date: string;
}

export interface IndividualSecondaryModel {
  id: string;
  name: string;
  identifier: string;
  type: string;
  transactionAmount: number;
  dateOfTransaction: string;
  numberOfShares: number;
  pps: number;
  securityTypes: string[];
  roundDescription: string;
  numberOfBuyer: number;
  numberOfSeller: number;
  capTable: IndividualSecondaryCapTable[];
  transactions: SecondaryTransaction[];
}

export interface SharesPerSecurity {
  investorId: string;
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
}
export interface SecondaryInvestors {
  id: string;
  name: string;
  type: string;
  sharesPerSecurity: SharesPerSecurity[];
}

// ------------------------Split Model-------------------------------

export interface IndividualSplitCapTable {
  id: string;
  shareholderName: string;
  shareholderType: string;
  rounds: RoundDetailModel;
  preHoldingFdbShares: number;
  postHoldingFdbShares: number;
  preHoldingCommonShares: number;
  postHoldingCommonShares: number;
  preHoldingPrefShares: number;
  postHoldingPrefShares: number;
}

export interface IndividualSplitModel {
  id: string;
  splitRatio: string;
  dateOfTransaction: string;
  securityTypes: string[];
  roundDescription: string;
  capTable: IndividualSplitCapTable[];
}

// ------------------Round Model--------------------------

export interface IndividualRoundCapTable {
  id: string;
  rounds: RoundDetailModel[];
  preHoldingFdbShares: number;
  preHoldingFdbPercentage: number;
  postHoldingFdbShares: number;
  postHoldingFdbPercentage: number;
  amountInvestedInRound: number;
  totalAmountInvested: number;
  shareholderName: string;
  shareholderType: string;
}

export interface AgGridTransactionsCaptable {
  id: string;
  rounds: RoundDetailModel[];
  preHoldingFdbShares: number;
  preHoldingFdbPercentage: number;
  postHoldingFdbShares: number;
  postHoldingFdbPercentage: number;
  amountInvestedInRound?: number;
  totalAmountInvested: number;
  shareholderName: string;
  shareholderType: string;
  preHoldingCommonShares?: number;
  preHoldingPrefShares?: number;
  preHoldingDebentures?: number;
  postHoldingCommonShares?: number;
  postHoldingDebentures?: number;
  postHoldingPrefShares?: number;
  numberOfShares?: number;
  transactionValue?: number;
  netWorth?: number;
  currency?: string;
  roundCurrency?: string;
  exchangeRate?: number;
  amountInvestedInCurrency?: number;
  amountInRoundCurrency?: number;
}

interface PrimaryTransaction {
  uuid?: string;
  amount: number;
  sharePrice: number;
  numberOfShares: number;
  companyInvestorId: string;
  instrumentClassId: string;
}

export interface IConvertible {
  investorId: string;
  investorName: string;
  securityId: string;
  securityName: string;
  numberOfShares: number;
  totalFdbShares: number;
  investmentAmount: number;
  conversionPrice: number;
  valuationApplied: number;
}

export interface Transactions {
  primaryTransactions: PrimaryTransaction[];
  secondaryTransactions: SecondaryTransaction[];
  esopPostRoundPercentage: number;
  convertibleDetails?: IConvertible[];
}

export interface PrimaryTransactionDataModel {
  name: string;
  investorType?: string;
  security: string;
  image?: string;
  sharePrice: number;
  instrumentClassId?: string;
  investorId?: string;
  numberOfShares: number;
  amountInvested: number;
  par?: number;
  conversionRatio: string;
}

export interface IndividualRoundsModel {
  id: string;
  name: string;
  identifier: string;
  type: string;
  amountRaised: number;
  dateOfTransaction: string;
  postMoney: number;
  preMoney: number;
  numberOfShares: number;
  pps: number;
  securityTypes: string[];
  dilution: number;
  isPricedRound: boolean;
  issuanceType: string;
  newShares: number;
  newFdbShares: number;
  roundDescription: string;
  capTable: IndividualRoundCapTable[];
  transactions: Transactions;
  paymentType?: string;
  nonPricedSecurityDetails?: SecurityTableDetail[];
  amountInvestedInCurrency?: number;
  exchangeRate?: number;
  currency?: string;
  authorizedEquity?: number;
  authorizedPreference?: number;
}

export enum DocumentType {
  document,
  link,
}

export interface TransactionDocumentInput {
  name: string;
  documentType: DocumentType;
  key: string;
  eventId: string;
  cin?: string;
  size?: number;
  tags?: string[];
}
